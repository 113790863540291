<template>
  <div class="modal" tabindex="-1" :id="id" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-ice">
        <div class="modal-header bg-cosmos p-3">
          <span>{{ headerTitel }}</span>
          <modal-close-button />
        </div>
        <div class="modal-body" style="display: flex; justify-content: center; align-items: center;">
          <img v-if="text !== null" :src="'https://' + text">
          <slot></slot>
        </div>
        <div class="modal-body" style="display: flex; justify-content: center; align-items: center;">
          <a v-if="link !== null"  href="#" @click="copyLinkToClipboard">
            {{ link }}
          </a>
          <slot></slot>
          <div hidden id="copied">{{ $t("global.copied") }}</div>
        </div>
        <div class="modal-footer">
          <div class="row w-100">
            <div class="col-12 text-center">
              <a v-if="text !== null" :href="'https://' + text" download="image.png">
                <button class="btn btn-primary mr-3">{{ $t("global.download") }}</button>
              </a>
              <a v-if="link !== null" :href="link" target="_blank">
               <button class="btn btn-primary mr-3">{{ $t("global.toqs") }}</button>
              </a>
              <button
                v-show="cancel"
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                @click="onCancel"
              >
                <span class="font-khand font-weight-bold">{{
                  cancelText
                }}</span>
              </button>
              <button
                v-show="repeat"
                type="button"
                class="btn btn-primary mr-3"
                data-dismiss="modal"
                @click="onRepeat"
              >
                <span class="font-khand font-weight-bold">{{
                  repeatText
                }}</span>
              </button>
              <button
                v-show="ok"
                type="button"
                class="btn btn-success"
                @click="onOk"
              >
                <span class="font-khand font-weight-bold">{{ okText }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "@/mixins/Modal";
import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "QrMessagebox",
  components: {
    ModalCloseButton,
  },
  props: {
    headerText: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      default: "messagebox-qr-modal",
    },
    ok: {
      type: Boolean,
      default: false,
    },
    cancel: {
      type: Boolean,
      default: true,
    },
    repeat: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: "OK",
    },
    cancelText: {
      type: String,
      default: "Abbrechen",
    },
    repeatText: {
      type: String,
      default: "Wiederholen",
    },
  },
  data() {
    return {
      text: null,
      headerTitel: null,
      link: null,
    };
  },
  mixins: [modal],
  mounted() {
    this.$bus.$on("openQrCodeMsgBox", (data) => {


      this.text = data ? data.img : this.text;
      this.link = data ? data.link : this.link;
      this.headerTitel = data.headerText ? data.headerText : this.headerText;

      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
    });
  },
  methods: {
        copyLinkToClipboard() {
      if (this.link) {
        // Create a temporary input element to copy the link to clipboard
        var text_to_copy = this.link;
         navigator.clipboard.writeText(text_to_copy).then(
        function(){
            alert(document.getElementById("copied").innerHTML)// success 
        })
      .catch(
         function() {
            alert("err"); // error
      });

        // Reset the status after a brief delay (e.g., 2 seconds)
        setTimeout(() => {
          this.$set(this, "isLinkCopied", false);
        }, 2000);
      }
    },
    closeModal() {
      $("#" + this.id).modal("hide");
    },
    onCancel() {
      this.closeModal();
      this.$emit("cancel");
    },
    onOk() {
      this.closeModal();
      this.$emit("ok");
    },
    onRepeat() {
      this.closeModal();
      this.$emit("repeat");
    },
    replaceWithBr() {
      return this.text.replace(/\n/g, "<br />");
    },
  },
};
</script>
