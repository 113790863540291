import { library } from "@fortawesome/fontawesome-svg-core";
import { faFileSpreadsheet } from "@fortawesome/pro-regular-svg-icons";

library.add(faFileSpreadsheet);

import FileDownload from "js-file-download";
import Api from "@/Api";
import ExportApi from "@/ExportApi";

export default {
  methods: {
    exportToAbacusWithPlugin(endpoint, title) {
      ExportApi.get("/abacus/" + endpoint, {}, {
        type: "json",
        title: title,
        responseType: "text",
      })
        .then((response) => {
          FileDownload(
            response.data,
            title + `.xml`,
            'application/xml'
          );
          this.loading = false;
          return new Date();
        })
        .catch(() => {
          setTimeout(() => {
            this.$notify({
              type: "error",
              title: "Aktion fehlgeschlagen",
              text: "Fehler beim Erstellen der Xml-Datei",
              duration: 7000,
            });
          }, 2000);
          this.loading = false;
          return null
        });
    },
  },
};
