<template>
  <div>
    <container-headline
      :headline="$t('skjv.questions')"
      :col="2"
    >
      <div class="col-xl-12">
        <button
          class="btn btn-primary btn-sm mx-2"
          @click="oeffneNeueFrageModal()"
          :disabled="!editable"
        >
          <font-awesome-icon icon="fa-regular fa-plus" class="mr-1" />
          {{ $t("global.new") }}
        </button>
      </div>
    </container-headline>
    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div
          class="eingabe-panel-kopf standortPanel geschaeftspartner-beleg-liste"
          ref="standortPanel"
        >
          <div class="row">
            <div class="col-xl-12">
              <div class="form-group">
                <b-table
                  ref="standorteTable"
                  tbody-tr-class="item"
                  show-empty
                  :items="questions"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  sort-icon-left
                  border-bottom
                  thead-class="sticky-white-bg"
                >
                  <template #cell(delete)="row">
                    <div class="d-flex align-items-center">
                      <button
                        class="btn btn-danger btn-sm"
                        @click="fragenLoeschen(row.item)"
                        style="height: 33px"
                        :disabled="!editable"
                      >
                        <font-awesome-icon
                          icon="fa-duotone fa-trash"
                          class="mr-1 pl-1"
                        />
                      </button>
                    </div>
                  </template>

                  <template #cell(edit)="row">
                    <div class="d-flex align-items-center">
                      <button
                        class="btn btn-primary btn-sm"
                        @click="frageBearbeiten(row.item)"
                        style="height: 33px"
                        :disabled="!editable"
                      >
                        <font-awesome-icon
                          icon="fa-duotone fa-arrow-circle-right"
                          class="mr-1 pl-1"
                        />
                      </button>
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center text-primary my-2">
                      <strong>{{ $t("global.nodataavailable") }}</strong>
                    </div>
                  </template>

                  <template #table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"
                      :key="field.key"
                      :style="{
                        width:
                          field.key === 'delete' || field.key === 'edit'
                            ? '10%'
                            : '30%',
                      }"
                    />
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <questionaire-fragen-modal
      id="questionaire-frage-modal"
      :questionaire="questionaireID"
      @confirmed="frageHinzufuegen"
    />
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import QuestionaireFragenModal from "@/components/Modals/TemplateQuestionaireFragenModal.vue";
import Questionaire from "../../pages/Questionaire/TemplateQuestionaire.vue";

export default {
  name: "Fragen",
  components: {
    ContainerHeadline,
    QuestionaireFragenModal,
  },
  mixins: [],
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    questionaireID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sortBy: "sort",
      sortDesc: false,
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "designation",
          sortable: true,
          label: this.$t("global.designation"),
          tdClass: "p-0 pb-2 pr-1 pl-3",
        },
        {
          key: "questiontype.designation",
          sortable: true,
          label: this.$t("global.type"),
          tdClass: "p-0 pb-2 pr-1 pl-3",
        },
        {
          key: "sort", // Assuming "sort" is the attribute you want to sort by
          sortable: true, // Enable sorting for this field
          label: this.$t("global.sorting"), // Label for the sorting column
          tdClass: "p-0 pb-2 pr-1 pl-3",
        },
        {
          key: "required",
          sortable: true,
          label: this.$t("global.requiredquestion"),
          tdClass: "p-0 pb-2 pr-1 pl-3",
          formatter: (value) => {
            if (value) {
              return "✓";
            } else {
              return "X";
            }
          },
        },
        {
          key: "delete",
          sortable: false,
          label: this.$t("global.delete"),
          tdClass: "p-0 pb-2 pl-3",
        },
        {
          key: "edit",
          sortable: false,
          label: this.$t("global.edit"),
          tdClass: "p-0 pb-2 pl-3",
        },
      ];
    },
  },
  created() {},
  watch: {},
  mounted: function () {},
  methods: {
    fragenLoeschen(item) {
      this.$emit("questionsLoeschen", item);
    },

    frageBearbeiten(item) {
      this.$bus.$emit("editQuestions", "questionaire-frage-modal", item);
    },

    frageHinzufuegen() {
      this.$emit("reloadQS");
    },
    oeffneNeueFrageModal() {
      this.$bus.$emit("openNeueFrageModal", "questionaire-frage-modal");
    },
  },
};
</script>

<style>
/* Firefox */
*.standortPanel {
  scrollbar-width: thin;
  scrollbar-color: #deedf6 #f5fbff;
}

/* Chrome, Edge, und Safari */
*.standortPanel::-webkit-scrollbar {
  width: 12px;
}

*.standortPanel::-webkit-scrollbar-track {
  background: #f5fbff;
  border-radius: 0 0 6px 0;
}

*.standortPanel::-webkit-scrollbar-thumb {
  background-color: #deedf6;
  border-radius: 20px;
  border: 3px solid #f5fbff;
}
</style>
