<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.relationships')"
        :col="3"
      >
        <div class="row mt-2 ml-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              v-if="editable"
              class="btn btn-success mr-2"
              @click="neueBeziehung"
            >
              <font-awesome-icon class="mr-1" icon="fa-regular fa-plus" />{{
                $t("global.new")
              }}
            </button>
            <button
              v-if="gptyp == 'firma'"
              class="btn btn-primary"
              :disabled="selectedIds.length == 0"
              @click="neueAnmeldung"
            >
              <font-awesome-icon
                class="mr-1"
                icon="fa-regular fa-list-check"
              />{{ $t("global.massregistration") }}
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                show-empty
                :busy="isBusy"
                :items="listData"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                sort-icon-left
                selectable
                select-mode="range"
                @row-dblclicked="details"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #cell(edit)="row" class="d-flex align-items-center">
                  <div
                    class="ml-5"
                    role="button"
                    @click="beziehungBearbeiten(row.item)"
                  >
                    <font-awesome-icon icon="fa-regular fa-pencil" size="sm" />
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <beziehung-modal
      @beziehungCreated="beziehungErstellt"
      @beziehungChanged="beziehungGeaendert"
      :defaultGP="defaultGP"
      :gptyp="gptyp"
    />

    <loeschen-modal
      id="beziehung-loeschen-modal"
      @confirmed="beziehungLoeschen"
      :selectedIds="selectedIds"
    />

    <massen-anmeldung-modal />
  </div>
</template>

<script>
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import reiter from "@/mixins/Reiter";
import BeziehungModal from "@/components/Geschaeftspartner/beziehungModal";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import MassenAnmeldungModal from "../../components/Modals/MassenAnmeldungModal.vue";
import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";

export default {
  name: "BeziehungReiter",
  components: {
    ContainerHeadline,
    BeziehungModal,
    BSpinner,
    LoeschenModal,
    MassenAnmeldungModal,
  },
  mixins: [reiter],
  props: {
    defaultGP: {
      type: Object,
      required: false,
    },
    gp: {
      type: String,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    gptyp: {
      type: String,
      required: true,
    },
  },
  data() {
    if (this.gptyp == "firma") {
      return {
        page: 0,
        anzahlProPage: 99,
        sortBy: "",
        sortDesc: false,
        beziehungen: [],
        listData: [],
        relationen: [],
        isBusy: false,
        ladeFehler: false,
      };
    } else if (this.gptyp == "person") {
      return {
        page: 0,
        anzahlProPage: 99,
        sortBy: "",
        sortDesc: false,
        beziehungen: [],
        listData: [],
        relationen: [],
        isBusy: false,
        ladeFehler: false,
        relationArtenIstDa: null,
      };
    }
  },
  watch: {
    shown(val) {
      if (
        val &&
        this.beziehungen.length == 0 &&
        this.relationarten.length !== 0
      ) {
        this.getBeziehungen();
      }
    },
    relationarten: {
      immediate: true,
      async handler(newValue, oldvalue) {
        if (!oldvalue) {
          await this.$store.dispatch(
            `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
          );
          this.getBeziehungen();
        }
      },
    },
  },
  computed: {
    fields() {
      if (this.gptyp == "firma") {
        return [
          { key: "selected", label: "" },
          {
            key: "partnerName",
            sortable: false,
            label: this.$t("global.who"),
          },
          {
            key: "relationstyp.bezeichnung",
            sortable: true,
            label: this.$t("global.relationship"),
          },
          {
            key: "defaultGP.name",
            sortable: true,
            label: this.$t("global.With"),
          },
          {
            key: "abteilung.bezeichnung",
            sortable: false,
            label: this.$t("global.company_department"),
          },
          {
            key: "defaultGP.name",
            sortable: true,
            label: this.$t("global.With"),
          },
          {
            key: "geschaeftspartner.telefon_g",
            sortable: false,
            label: this.$t("global.phoneb"),
          },
          {
            key: "geschaeftspartner.mailadressen[0].bezeichnung",
            sortable: false,
            label: this.$t("global.email"),
          },
          /*TODO: Funktion einbauen (Issue BXEDU-382)
          {
            key: "kontakt",
            sortable: false,
            label: "Kontakt",
          },*/
          {
            key: "partner.adressen[0].adresszusatz",
            sortable: true,
            label: this.$t("global.addresssuffix"),
          },
          {
            key: "partner.adressen[0].strasse_nr",
            sortable: true,
            label: this.$t("global.street"),
          },
          {
            key: "partner.adressen[0].plz",
            sortable: true,
            label: this.$t("global.zipcode"),
          },
          {
            key: "partner.adressen[0].ort",
            sortable: true,
            label: this.$t("global.city"),
          },
          {
            key: "partner.adressen[0].land.name",
            sortable: true,
            label: this.$t("global.country"),
          },
          { key: "edit", sortable: false, label: this.$t("global.edit") },
        ];
      } else {
        return [
          { key: "selected", label: "" },
          {
            key: "relationstyp.bezeichnung",
            sortable: true,
            label: this.$t("global.relationship"),
          },
          {
            key: "partnerName",
            sortable: false,
            label: this.$t("global.With"),
          },
          {
            key: "abteilung.bezeichnung",
            sortable: false,
            label: this.$t("global.personen_department"),
          },
          {
            key: "relationsstatus.bezeichnung",
            sortable: false,
            label: this.$t("global.status"),
          },
          {
            key: "firma.telefon_g",
            sortable: false,
            label: this.$t("global.phoneb"),
          },
          {
            key: "firma.mailadressen[0].bezeichnung",
            sortable: false,
            label: this.$t("global.email"),
          },
          /*TODO: Funktion einbauen (Issue BXEDU-382)
          {
            key: "kontakt",
            sortable: false,
            label: "Kontakt",
          },*/
          {
            key: "partner.adressen[0].adresszusatz",
            sortable: true,
            label: this.$t("global.addresssuffix"),
          },
          {
            key: "partner.adressen[0].strasse_nr",
            sortable: true,
            label: this.$t("global.street"),
          },
          {
            key: "partner.adressen[0].plz",
            sortable: true,
            label: this.$t("global.zipcode"),
          },
          {
            key: "partner.adressen[0].ort",
            sortable: true,
            label: this.$t("global.city"),
          },
          {
            key: "partner.adressen[0].land.name",
            sortable: true,
            label: this.$t("global.country"),
          },
          { key: "edit", sortable: false, label: this.$t("global.edit") },
        ];
      }
    },
    relationarten() {
      if (this.$store) {
        return this.$store.state.geschaeftspartner.relationarten;
      } else {
        return [];
      }
    },
  },
  mounted() {
    if (this.gptyp == "person") {
      this.setHeaders("global", [
        "",
        "relationship",
        "With",
        "personen_department",
        "status",
        "phoneb",
        "email",
        "addresssuffix",
        "street",
        "zipcode",
        "city",
        "country",
        "edit",
      ]);
    }

    if (this.gptyp == "firma") {
      this.setHeaders("global", [
        "",
        "who",
        "relationship",
        "With",
        "companies_department",
        "status",
        "phoneb",
        "email",
        "addresssuffix",
        "street",
        "zipcode",
        "city",
        "country",
        "edit",
      ]);
    }
  },
  created() {},
  methods: {
    getBeziehungen() {
      if (!this.gp) return;

      this.isBusy = true;

      Api.get("geschaeftspartner/relation/", {
        params: {
          gp: this.gp,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          var beziehungen = [];

          //Setze aktuellen Geschaeftspartner und Partner je nachdem ob Geschaeftspartner eine Firma oder Person ist und ob Partner eine Firma oder Person ist, und setze die Relationsart, sowie Anzeigenamen in Tabelle
          response.data.forEach((data, index) => {
            beziehungen[index] = data;

            beziehungen[index].defaultGP = this.defaultGP;

            //aktueller Geschaeftspartner ist eine Firma
            if (this.gptyp == "firma") {
              if (data.relation_mit || data.geschaeftspartner) {
                //Partner ist Person
                beziehungen[index].partner = data.relation_mit
                  ? data.relation_mit
                  : data.geschaeftspartner;
                beziehungen[index].partner.partnerTyp = "person";
                beziehungen[index].relationart = this.relationarten.find(
                  (art) => art.id == "180BD27BB10" //Firma - Person
                );
              } else if (data.firma2 && data.firma) {
                //Partner ist Firma
                if (data.firma2?.id != this.gp) {
                  beziehungen[index].partner = data.firma2;
                } else {
                  beziehungen[index].partner = data.firma;
                }
                beziehungen[index].partner.partnerTyp = "firma";
                beziehungen[index].relationart = this.relationarten.find(
                  (art) => art.id == "18040DD8589" //Firma - Firma
                );
              }
            }
            //aktueller Geschaeftspartner ist eine Person
            else {
              if (data.firma || data.firma2) {
                //Partner ist Firma
                beziehungen[index].partner = data.firma2
                  ? data.firma2
                  : data.firma;
                beziehungen[index].partner.partnerTyp = "firma";
                beziehungen[index].relationart = this.relationarten.find(
                  (art) => art.id == "18040DDBB0E" //Person - Firma
                );
              } else if (data.relation_mit && data.geschaeftspartner) {
                //Partner ist Person
                if (data.relation_mit?.id != this.gp) {
                  beziehungen[index].partner = data.relation_mit;
                } else {
                  beziehungen[index].partner = data.geschaeftspartner;
                }
                beziehungen[index].partner.partnerTyp = "person";
                beziehungen[index].relationart = this.relationarten.find(
                  (art) => art.id == "18098547520" //Person - Person
                );
              }
            }

            //Setze den anzuzeigenden Namen des Partners
            beziehungen[index].partnerName = beziehungen[index]?.partner
              ?.personName
              ? beziehungen[index].partner?.personName
              : beziehungen[index].partner?.name;
          });

          this.listData = beziehungen;

          this.ladefehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    neueBeziehung() {
      this.$bus.$emit("beziehung-modal-show", this.defaultGP);
    },

    beziehungBearbeiten(beziehung) {
      this.$bus.$emit("beziehung-editieren", beziehung);
    },

    beziehungErstellt(beziehungEintrag) {
      let json = beziehungEintrag;

      //Setze aktuellen GP (von Maske) und leere die anderen Felder
      json.geschaeftspartner = this.gptyp == "firma" ? {} : this.defaultGP;
      json.firma = this.gptyp == "firma" ? this.defaultGP : {};

      //Setze Partner und leere die anderen Felder
      json.relation_mit =
        json.partner?.partnerTyp == "firma" ? {} : json.partner;
      json.firma2 = json.partner?.partnerTyp == "firma" ? json.partner : {};

      Api.post("geschaeftspartner/relation/", json)
        .then(() => {
          this.getBeziehungen();
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.relationshipsuccessfullysaved"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          if(json.use_kommunikationsadresse)
          this.$emit("reloadGP", this.beziehung);
          this.loading = false;
        });
    },

    beziehungGeaendert(beziehungEintrag) {
      let json = beziehungEintrag;

      //Setze aktuellen GP (von Maske)
      json.geschaeftspartner = this.gptyp == "firma" ? {} : this.defaultGP;
      json.firma = this.gptyp == "firma" ? this.defaultGP : {};

      //Setze Partner
      json.relation_mit =
        json.partner?.partnerTyp == "firma" ? {} : json.partner;
      json.firma2 = json.partner?.partnerTyp == "firma" ? json.partner : {};

      Api.put("geschaeftspartner/relation/", json, {
        params: { id: json.id },
      })
        .then(() => {
          this.getBeziehungen();
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.relationshipsuccessfullysaved"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          if(json.use_kommunikationsadresse)
          this.$emit("reloadGP", this.beziehung);

          this.loading = false;
        });
    },

    details(beziehung) {
      if (beziehung.partner?.partnerTyp == "person") {
        this.$router.push({
          name: "geschaeftspartner",
          params: { id: beziehung.partner?.id, anzeige: 0 },
        });
      } else if (beziehung.partner?.partnerTyp == "firma") {
        this.$router.push({
          name: "firma",
          params: { id: beziehung.partner?.id, anzeige: 0 },
        });
      }
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "beziehung-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noselectedrelationships"),
        });
      }
    },

    beziehungLoeschen() {
      this.isBusy = true;

      Api.delete("geschaeftspartner/relation/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getBeziehungen();
      });
    },

    neueAnmeldung() {
      let personen = [];
      this.selectedObjects.forEach((person) => {
        if (person.geschaeftspartner) {
          personen.push(person.geschaeftspartner.id);
        }
      });

      this.$bus.$emit("open-massenanmeldung-modal", personen);
    },
  },
};
</script>
