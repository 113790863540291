<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <ValidationObserver v-slot="{ invalid }">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">{{ $t("skjv.createquestion") }}</h4>
          </div>
          <div class="modal-body">
            <div class="col-xl-12 pr-4">
              <!-- START Stammdaten -->
              <container-headline
                :headline="$t('global.masterdata')"
                :col="6"
              ></container-headline>

              <div class="row mb-3">
                <div class="col-xl-12 block br-t-l-0">
                  <div class="eingabe-panel-kopf p-3">
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <v-select
                            v-model="questiontype"
                            label="designation"
                            :options="questiontypes"
                            :placeholder="$t('global.type')"
                            required
                          >
                            <span slot="no-options">{{
                              $t("global.taptosearch")
                            }}</span>
                          </v-select>
                          <label
                            v-bind:class="{ 'text-danger': !typeValidated }"
                            >{{ $t("global.type") }}</label
                          >
                        </div>
                      </div>
                      <div class="col-xl-3">
                        <div class="form-group">
                          <input
                            class="form-control"
                            type="number"
                            placeholder=" "
                            v-model.number="sort"
                            step="0.1"
                            min="0"
                          />
                          <label>{{ $t("global.sorting") }}/{{ $t("global.numbering") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-3">
                        <div class="form-check">
                          <input
                            v-model="required"
                            class="form-check-input"
                            type="checkbox"
                            :tabindex="110"
                            :readonly="!editable"
                          />
                          <label class="form-check-label">{{
                            $t("global.requiredquestion")
                          }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="form-group">
                          <textarea
                            v-model="designation"
                            class="form-control"
                            placeholder=" "
                            rows="2"
                          ></textarea>
                          <label
                            v-bind:class="{ 'text-danger': !desValidated }"
                            >{{ $t("global.designation") }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="form-group">
                          <textarea
                            v-model="description"
                            class="form-control"
                            placeholder=" "
                            rows="4"
                          ></textarea>
                          <label>{{ $t("global.comment") }}</label>
                        </div>
                      </div>
                    </div>
                    <div v-if="questiontype.id == '1864FF4F374'" class="row">
                      <!--Range-->
                      <div class="col-xl-6">
                        <div class="form-group">
                          <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                          >
                            <input
                              v-model="rangestart"
                              type="number"
                              class="form-control"
                              placeholder=""
                              v-bind:class="{ 'border-danger': !passed }"
                            />
                            <label v-bind:class="{ 'text-danger': !passed }">
                              {{ $t("skjv.start") }}
                            </label>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <ValidationProvider
                            rules="required"
                            immediate
                            v-slot="{ passed }"
                          >
                            <input
                              v-model="rangeend"
                              type="number"
                              class="form-control"
                              placeholder=""
                              v-bind:class="{ 'border-danger': !passed }"
                            />
                            <label v-bind:class="{ 'text-danger': !passed }">
                              {{ $t("skjv.end") }}
                            </label>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div v-if="questiontype.id == '18692CEBDE2'" class="row">
                      <!--Multiplechoice-->
                      <div class="col-xl-6">
                        <div class="form-group">
                          <input
                            v-model="answercount"
                            class="form-control"
                            placeholder=""
                          />
                          <label>
                            {{ $t("skjv.minanswercount") }}
                          </label>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <input
                            v-model="maxanswercount"
                            type="number"
                            class="form-control"
                            placeholder=""
                          />
                          <label>
                            {{ $t("skjv.maxanswercount") }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ENDE Stammdaten -->

              <div
                v-if="
                  questiontype.id == '18692CEBDE2' ||
                  questiontype.id == '1864FF4DEB1'
                "
              >
                <!--Multiplechoice || Singlechoice-->
                <container-headline
                  :headline="$t('skjv.answers')"
                  :col="2"
                >
                  <div class="col-xl-12">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="answerHinzufuegen()"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" class="mr-1"/>
                      {{ $t("global.new") }}
                    </button>
                  </div>
                </container-headline>
                <div class="row mb-3">
                  <div class="col-xl-12 block br-t-l-0">
                    <div
                      class="eingabe-panel-kopf p-3 abteilungPanel"
                      ref="abteilungPanel"
                      :style="
                        answers.length > 4 ? abteilungPanelStyle : 'height: 246.5px;'
                      "
                    >
                      <div class="row">
                        <div class="col-xl-12">
                          <div class="form-group">
                            <draggable 
                              v-model="answers" 
                              @end="updateAnswerOrder" 
                              tag="div" 
                              :options="{ handle: '.handle' }"
                              :class="draggable ? 'cursor' : ''"
                            >
                              <div 
                                v-for="answer in answers" 
                                :key="answer.id" 
                                class="d-flex align-items-center mb-2"
                                :class="draggable ? 'cursor' : ''"
                                >
                                <font-awesome-icon 
                                  icon="fa-solid fa-bars" 
                                  class="mr-1 pl-1 handle mr-2 cursor"
                                  :title="$t('global.sorting')"
                                />
                                <input 
                                  v-model="answer.designation" 
                                  class="form-control" 
                                  :placeholder="$t('skjv.answer')" 
                                />
                                <input 
                                  type="hidden" 
                                  v-model="answer.sort" 
                                />
                                <button 
                                  class="btn btn-danger btn-sm ml-2" 
                                  @click="answerLoeschen(answer)" 
                                  style="height: 33px"
                                >
                                  <font-awesome-icon icon="fa-duotone fa-trash" class="mr-1 pl-1"/>
                                </button>
                              </div>
                                <div
                                  v-if="answers.length == 0"
                                  class="text-center text-primary my-2">
                                  <strong>{{
                                    $t("global.nodataavailable")
                                  }}</strong>
                                </div>
                            </draggable>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              type="button"
              :disabled="!typeValidated || !desValidated || invalid"
              class="btn btn-primary"
              @click="speichern"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <loading-overlay v-if="loading"></loading-overlay>
  </div>
</template>

<script>
import QuestionaireApi from "@/QuestionaireApi";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import ContainerHeadline from "@/components/ContainerHeadline";
import draggable from 'vuedraggable';

import store from "@/store";

import page from "@/mixins/Page";

import ModalCloseButton from "@/components/global/ModalCloseButton";

import LoadingOverlay from "@/components/global/LoadingOverlay";

import { LADE_AUSWAHLWERTE_QUESTIONAIRE } from "@/store/questionaire/actions.type";

export default {
  name: "TemplateNeueFrageModal",
  components: {
    ContainerHeadline,
    ModalCloseButton,
    LoadingOverlay,
    draggable
  },
  mixins: [page],
  store,
  props: {
    id: {
      type: String,
      required: true,
    },
    questionaire: {
      type: String,
      required: false,
    },
    question: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      openNeueFrageModalHandler: null,
      editQuestionsHandler: null,
      required: false,
      sortBy: "",
      sortDesc: false,
      loading: false,
      shown: false,
      rangestart: "",
      rangeend: "",
      answercount: "",
      maxanswercount:"",
      description: "",
      designation: "",
      questiontype: "",
      sort: "",
      answers: [],
      answerstodelete: [],
      abteilungPanelStyle: {
        height: "246.5px",
        "overflow-y": "scroll",
      },
      typeValidated: false,
      desValidated: false,
    };
  },
  watch: {
    questiontype(val) {
      if (val) {
        this.typeValidated = true;
      } else {
        this.typeValidated = false;
      }
    },
    designation(val) {
      if (val) {
        this.desValidated = true;
      } else {
        this.desValidated = false;
      }
    },
  },
  computed: {
    fields() {
      return [
        {
          key: "designation",
          sortable: true,
          label: this.$t("global.designation"),
          tdClass: "p-0 pb-2 pr-1 pl-3",
        },
        {
          key: "sort",
          sortable: false,
          label: this.$t("global.sort"),
          tdClass: "p-0 pb-2 pl-3 align-items-center",
        },
        {
          key: "delete",
          sortable: false,
          label: this.$t("global.delete"),
          tdClass: "p-0 pb-2 pl-1 d-flex align-items-center",
        },
      ];
    },
    mitarbeiter: {
      get() {
        return this.$store.state.mitarbeiter;
      },
    },
    questiontypes: {
      get() {
        return this.$store.state.questionaire.questiontypes;
      },
    },
  },
  mounted() {
    if (this.questiontypes.length == 0) {
      this.$store.dispatch(`questionaire/${LADE_AUSWAHLWERTE_QUESTIONAIRE}`);
    }

    this.openNeueFrageModalHandler = (id) => {
      this.required = false;
      this.description = "";
      this.designation = "";
      this.answers = [];
      this.rangestart = "";
      this.rangeend = "";
      this.answercount = "";
      this.maxanswercount = "";
      this.questiontype = "";
      this.questionID = "";
      this.validated = false;
      this.sort = 0;
      this.answerstodelete = [];
      if (!this.shown) this.openModal(id);
    };
    this.$bus.$on("openNeueFrageModal", this.openNeueFrageModalHandler);

    this.editQuestionsHandler = (id, item) => {
      if (!this.shown) this.openModal(id);
      if (item) {
        this.required = item.required;
        this.description = item.description;
        this.designation = item.designation;
        this.answers = item.answers;
        this.rangestart = item.rangestart;
        this.rangeend = item.rangeend;
        this.answercount = item.answercount;
        this.maxanswercount = item.maxanswercount;
        this.questiontype = item.questiontype;
        this.questionID = item.id;
        this.sort = item.sort;
        this.answerstodelete = [];
      }
    };
    this.$bus.$on("editQuestions", this.editQuestionsHandler);
  },
  beforeDestroy() {
    this.$bus.$off("openNeueFrageModal", this.openNeueFrageModalHandler);
    this.$bus.$off("editQuestions", this.editQuestionsHandler);
  },
  methods: {
    openModal(id) {
      this.shown = true;

      const thisId = id ? id : this.id;

      this.reserviert_durch = this.mitarbeiter.person;

      $("#" + thisId).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    updateAnswerOrder() {
      this.answers.forEach((answer, index) => {
        answer.sort = index + 1;
      });
    },

    answerLoeschen(answer) {
      const indexToRemove = answer.date ? this.answers.findIndex(item => item.date === answer.date) : this.answers.findIndex(item => item.id === answer.id);

      // Check if the item with the given "id" exists in the array
      if (indexToRemove !== -1) {
        // Remove the item from the array
        this.answerstodelete.push(this.answers[indexToRemove]);
        this.answers.splice(indexToRemove, 1);
      }
    },
    answerHinzufuegen() {
      this.answers.push({id: "", designation: "", date: new Date()});
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = {
        required: this.required,
        questionaire: this.questionaire,
        description: this.description,
        designation: this.designation,
        answers: this.answers,
        rangestart: this.rangestart,
        rangeend: this.rangeend,
        answercount: this.answercount,
        maxanswercount: this.maxanswercount,
        questiontype: this.questiontype,
        answerstodelete: this.answerstodelete,
        sort: this.sort,
      };

      if (!this.questionID) {
        QuestionaireApi.post(
          "templatequestion/" + "?qs=" + json.questionaire,
          json
        )
          .then((response) => {
            //this.initializeQuestionaire(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.questionsavedsuccessfully"),
            });

            if (response.status === 200) this.closeModal();
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
            this.$emit("confirmed");
          });
      } else {
        QuestionaireApi.put("templatequestion/" + this.questionID, json)
          .then((response) => {
            //this.initializeQuestionaire(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.questionsavedsuccessfully"),
            });

            if (response.status === 200) this.closeModal();
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
            this.$emit("confirmed");
          });
      }
    },
  },
};
</script>

<style>
/* Firefox */
*.abteilungPanel {
  scrollbar-width: thin;
  scrollbar-color: #deedf6 #f5fbff;
}

/* Chrome, Edge, und Safari */
*.abteilungPanel::-webkit-scrollbar {
  width: 12px;
}

*.abteilungPanel::-webkit-scrollbar-track {
  background: #f5fbff;
  border-radius: 0 0 6px 0;
}

*.abteilungPanel::-webkit-scrollbar-thumb {
  background-color: #deedf6;
  border-radius: 20px;
  border: 3px solid #f5fbff;
}
</style>
