<template>
    <div id="umfrage-vorlage-modal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <ValidationObserver v-slot="{ invalid }" :disabled="true">
            <div class="modal-header">
              <modal-close-button @confirmed="closeModal" />
              <h4 class="modal-title">{{ $t("global.selecttemplate") }}</h4>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-xl-12">
                  <span>
                    {{
                      $t(
                        "global.selectthetemplatethatyouwanttouseforthecurrentsurvey"
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-xl-12">
                  <div class="form-group">
                    <ValidationProvider
                      rules="required"
                      immediate
                      v-slot="{ passed }"
                    >
                      <v-select
                        v-model="vorlage"
                        class="mt-2 mb-3"
                        v-bind:class="{ 'border-danger': !passed }"
                        label="bezeichnung"
                        :options="vorlagen"
                        :getOptionLabel="(vorlage) => vorlage.designation"
                        :reduce="(vorlage) => vorlage.id"
                        :placeholder="$t('global.template')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label v-bind:class="{ 'text-danger': !passed }">{{
                        $t("global.template")
                      }}</label>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" @click="closeModal">
                {{ $t("global.cancel") }}
              </button>
              <button
                type="button"
                :disabled="invalid"
                class="btn btn-primary"
                @click="uebernehmen"
              >
                {{ $t("global.apply") }}
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  import QuestionaireApi from "@/QuestionaireApi";
  import ModalCloseButton from "@/components/global/ModalCloseButton";
  import { LADE_MAIL_VORLAGEN } from "@/store/korrespondenz/actions.type";
  
  export default {
    name: "MailVorlageModal",
    components: {
      ModalCloseButton,
    },
    props: {},
    data() {
      return {
        shown: false,
        vorlage: null,
        vorlagen: []
      };
    },
    computed: {},
    watch: {},
    created() {
    },
    mounted() {
      this.$bus.$on("open-umfrage-vorlage-modal", () => {
        if (!this.shown) this.openModal();
      });
    },
    methods: {
      openModal() {
        if (this.vorlagen.length == 0)
            QuestionaireApi.get("templateassessment/", {
            params: { 
                page: 1,
                size: 999,
            },
        }).then((response) => {
            console.log(response.data)
            this.vorlagen = response.data.content;
        });
  
        this.shown = true;
  
        $("#umfrage-vorlage-modal").modal({
          backdrop: "static",
          keyboard: false,
        });
      },
      closeModal() {
        this.shown = false;
        $("#umfrage-vorlage-modal").modal("hide");
      },
      uebernehmen() {
        this.$emit("confirmed", this.vorlage);
        this.closeModal();
      },
    },
  };
  </script>
  
  <style></style>
  