import Vue from 'vue';
import Notifications from 'vue-notification';

Vue.use(Notifications);

//Setze Anzeigedauer der Notification anhand des Notificationtyps (success, warn, error)

const DEFAULT_DURATION = 5000;
const WARN_DURATION = 15000;
const ERROR_DURATION = 30000;

const originalNotify = Vue.prototype.$notify;

Vue.prototype.$notify = function (options) {
//Erstelle ein Objekt, falls nur ein String übergeben wird
  if (typeof options === 'string') {
    options = { text: options };
  }

  //Setze Anzeigedauer, wenn nicht schon eine explizite Anzeigedauer übergeben wird
  switch (options.type) {
	case "success":
		options.duration = options.duration || DEFAULT_DURATION;
		break;
	case "warn":
		options.duration = options.duration || WARN_DURATION;
		break;
	case "error":	
		options.duration = options.duration || ERROR_DURATION;
		break;
	default:
		options.duration = options.duration || DEFAULT_DURATION;
  }


  originalNotify.call(this, options);
};