var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100-p overflow-auto max-h-70-vh styled-scrollbar"},[_c('b-table',{ref:"dubletteTable",attrs:{"small":"","hover":"","selectable":"","tbody-tr-class":"item","select-mode":"range","items":_vm.tableData,"fields":_vm.fields},scopedSlots:_vm._u([{key:"thead-top",fn:function(row){return _vm._l((row.fields),function(field,index){return _c('td',{key:'head-row-' + field.key,staticClass:"border-none"},[(index === 0)?_c('div',{staticClass:"btn btn-success",on:{"click":_vm.dublettenZusammenfuehren}},[_vm._v(" "+_vm._s(_vm.$t("global.mergeduplicates"))+" ")]):_vm._e(),(index > 1 && !_vm.eintrag.duplicates[index - 2].locked)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-outline-secondary",attrs:{"title":_vm.$t('global.entrytooltiptext')},on:{"click":function($event){return _vm.sperreEintrag(field.key, index)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-solid fa-unlock"}}),_c('span',[_vm._v(_vm._s(_vm.$t("global.lockentry")))])],1):_vm._e(),(index > 1 && _vm.eintrag.duplicates[index - 2].locked)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-outline-danger",attrs:{"title":_vm.$t('global.entrytooltiptext')},on:{"click":function($event){return _vm.entsperreEintrag(field.key, index)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"fa-solid fa-lock"}}),_c('span',[_vm._v(_vm._s(_vm.$t("global.entrylocked")))])],1):_vm._e()])})}},{key:"top-row",fn:function(ref){
var fields = ref.fields;
return _vm._l((fields),function(field,index){return _c('td',{key:'top-row-' + index},[(field.label != '')?_c('div',{staticClass:"d-flex align-items-center"},[_c('button',{ref:("button-" + (field.key)),refInFor:true,staticClass:"btn btn-outline-primary btn-sm",on:{"click":function($event){return _vm.setAllRadioButtonsOfColumn(field.key)}}},[_vm._v(" "+_vm._s(_vm.$t("global.selectall"))+" ")])]):_vm._e()])})}},{key:"cell()",fn:function(cell){return [(
          typeof cell.value === 'object' &&
          ('street_nr' in cell.value ||
            'zip' in cell.value ||
            'city' in cell.value)
        )?_c('div',{staticClass:"d-flex align-items-center"},[(cell.field.key != 'description')?_c('input',{ref:("radio-" + (cell.field.key) + "-" + (cell.index)),staticClass:"mr-2",attrs:{"type":"radio","data-cellkey":cell.field.key,"data-cellindex":cell.index,"data-cellduplicateindex":cell.field.duplicateIndex,"name":'radio-' + cell.index},domProps:{"value":cell.value},on:{"click":function($event){return _vm.setMasterValue(
              cell.field.key,
              cell.index,
              cell.field.duplicateIndex
            )}}}):_vm._e(),(cell.value.street_nr || cell.value.zip || cell.value.city)?_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(cell.value.street_nr ? cell.value.street_nr : "")+" "+_vm._s(cell.value.zip ? ", " + cell.value.zip + " " : "")+" "+_vm._s(cell.value.city ? cell.value.city : "")+" ")]):_c('div',[_vm._v("-")])]):_c('div',{staticClass:"d-flex align-items-center"},[(cell.field.key != 'description')?_c('input',{ref:("radio-" + (cell.field.key) + "-" + (cell.index)),staticClass:"mr-2",attrs:{"type":"radio","data-cellkey":cell.field.key,"data-cellindex":cell.index,"data-cellduplicateindex":cell.field.duplicateIndex,"name":'radio-' + cell.index},domProps:{"value":cell.value},on:{"click":function($event){return _vm.setMasterValue(
              cell.field.key,
              cell.index,
              cell.field.duplicateIndex
            )}}}):_vm._e(),_c('div',[_vm._v(_vm._s(cell.value ? cell.value : "-"))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }