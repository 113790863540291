<template>
  <div class="list">
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.blockingdays')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="tableFilter"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.searchkeyword") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <label>{{ $t("global.filterby") }}</label>
                      <b-form-checkbox-group v-model="tableFilterOn">
                        <b-form-checkbox
                          class="form-checkbox-input"
                          value="bezeichnung"
                          >{{ $t("global.designation") }}</b-form-checkbox
                        ><br />
                        <b-form-checkbox
                          class="form-checkbox-input"
                          value="von"
                          >{{ $t("global.from") }}</b-form-checkbox
                        ><br />
                        <b-form-checkbox
                          class="form-checkbox-input"
                          value="bis"
                          >{{ $t("global.till") }}</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.settingblockingdates')"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row">
                  <div class="ml-4 mr-2">
                    <button
                      v-if="berechtigungen.m_bildung.create"
                      class="btn btn-success"
                      @click="oeffneBearbeitenModal"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </button>
                  </div>
                  <div class="mr-2">
                    <button
                      v-if="berechtigungen.m_bildung.delete"
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="sperrtermine"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    :sort-compare="myCompare"
                    :filter="tableFilter"
                    :filter-included-fields="tableFilterOn"
                    @filtered="filterData"
                    @row-dblclicked="oeffneBearbeitenModal"
                  >
                    <template v-slot:head(selected)="row">
                      <b-form-checkbox
                        v-model="row.selected"
                        @input="selectAllRows(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-bearbeiten" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <modal-close-button @confirmed="closeBearbeitenModal" />
            <h4>{{ $t("global.editperiod") }}</h4>
          </div>
          <div class="modal-body">
            <div class="row p-0">
              <div class="col-xl-12">
                <div class="form-group">
                  <input
                    :readonly="!editable"
                    v-model="editTag.bezeichnung"
                    class="form-control"
                    :tabindex="1"
                    placeholder=" "
                  />
                  <label>{{ $t("global.designation") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <date-picker
                    :disabled="!editable"
                    date-format="dd.mm.yy"
                    :initial="editTag.von"
                    @update="(val) => (editTag.von = val)"
                    :tabindex="2"
                  />
                  <label>{{ $t("global.from") }}</label>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <date-picker
                    :disabled="!editable"
                    date-format="dd.mm.yy"
                    :initial="editTag.bis"
                    @update="(val) => (editTag.bis = val)"
                    :tabindex="3"
                  />
                  <label>{{ $t("global.till") }}</label>
                </div>
              </div>
              <div class="col-xl-9">
                <div class="form-group">
                  <v-select
                    :disabled="!editable"
                    v-model="editTag.raumliste"
                    label="bezeichnung"
                    :options="raeume"
                    :reduce="(r) => r.id"
                    :placeholder="$t('global.room')"
                    :tabindex="4"
                    multiple
                  >
                    <span slot="no-options">{{
                      $t("global.taptosearch")
                    }}</span>
                  </v-select>
                  <label>{{ $t("global.room") }}</label>
                </div>
              </div>
              <div class="col-xl-3">
                <div class="form-group">
                  <button
                    :disabled="!editable"
                    class="btn btn-default"
                    @click="alleRaeume"
                  >
                    {{ $t("global.all") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-default" @click="closeBearbeitenModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              v-if="editable"
              class="btn btn-primary"
              @click="sperrtagSpeichern"
            >
              {{ $t("global.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal @confirmed="sperrtagloeschen" :multiple="true" />
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import reiter from "@/mixins/Reiter";

import DatePicker from "@/components/Datepicker";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import ModalCloseButton from "@/components/global/ModalCloseButton";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

export default {
  name: "Bildungsliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    ModalCloseButton,
    DatePicker,
  },
  metaInfo() {},
  mixins: [page, reiter],
  props: {},
  store,
  data() {
    return {
      page: 0,
      anzahlProPage: 999,

      editTag: {},
      filters: {},
      tableFilter: null,
      tableFilterOn: [],

      sortBy: "Bezeichnung",
      sortDesc: true,
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        { key: "von", label: this.$t("global.from"), sortable: true },
        { key: "bis", label: this.$t("global.till"), sortable: true },
        {
          key: "bezeichnung",
          label: this.$t("global.designation"),
          sortable: true,
        },
        {
          key: "raumliste",
          sortable: true,
          label: this.$t("global.rooms"),
          thStyle: "width: 50%",
          formatter: (value) => {
            return value == null || value.length == 0
              ? ""
              : value.map((r) => r.bezeichnung).join(", ");
          },
        },
      ];
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      return params;
    },
    sperrtermine: {
      get() {
        return this.$store.state.ressourcen.sperrtermine;
      },
    },
    raeume: {
      get() {
        return this.$store.state.ressourcen.raeume;
      },
    },
  },
  watch: {
    filters: {
      handler: function () {
        //this.loadData(this);
      },
      deep: true,
    },
  },
  mounted: function () {
    if (this.sperrtermine.length == 0) this.loadData();
    this.setHeaders("global", ["", "from", "till", "designation", "rooms"]);
    this.editable = this.berechtigungen.m_bildung.update;
  },
  created: function () {
    this.dateCols = ["von", "bis"];
  },
  methods: {
    loadData() {
      this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);
    },
    filterData() {},
    alleRaeume() {
      //this.editTag.raumliste = [...this.raeume];
      this.$set(this.editTag, "raumliste", [...this.raeume]);
    },
    setFilterDate(data) {
      this.$set(this.filters, data.field, data.value);
      if (this.lastAPICall) this.resetLoading(this);
    },
    sperrtagSpeichern() {
      var json = Object.assign({}, this.editTag);
      if (this.editTag.id) {
        Api.put("sperrtermin/", json, {
          params: { id: this.editTag.id },
        })
          .then(() => {
            this.loadData();

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.blockingdaysavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          });
      } else {
        Api.post("sperrtermin/", json)
          .then(() => {
            this.loadData();

            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.blockingdaysavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          });
      }
      this.closeBearbeitenModal();
    },

    oeffneBearbeitenModal(sperrtermin) {
      if (sperrtermin) {
        Api.get("sperrtermin/", {
          params: { id: sperrtermin.id },
        }).then((response) => {
          this.editTag = Object.assign({}, response.data);

          $("#modal-bearbeiten").modal({
            backdrop: "static",
            keyboard: false,
          });
        });
      }
    },

    leereBearbeitenModal() {
      this.editTag = {};
    },

    closeBearbeitenModal() {
      this.leereBearbeitenModal();
      $("#modal-bearbeiten").modal("hide");
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) this.$bus.$emit("openLoeschenModal");
    },

    sperrtagloeschen() {
      Api.delete("ressourcen/auswahlwerte/", {
        params: {
          entity: "sperrtermin",
          ids: this.selectedIds.join(","),
        },
      }).then(() => {
        this.loadData();
      });
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>

<style></style>
