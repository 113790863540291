<template>
  <div class="toggleBackground">
    <input
      id="toggle-on" 
      class="toggle toggle-left"
      name="toggle" 
      type="radio" 
      @change="toggle"
      :checked="!selectedOption"
    >
    <label for="toggle-on" class="btn">{{ option1 }}</label>
    <input
      id="toggle-off" 
      class="toggle toggle-right"
      name="toggle" 
      type="radio"
      @change="toggle"
      :checked="selectedOption"
    >
    <label for="toggle-off" class="btn">{{ option2 }}</label>
  </div>
</template>

<script>
export default {
  name: "ToggleButton",
  props: {
    toggleValue: {
      type: Boolean,
      required: true,
      default: null
    },
    option1: {
      type: String,
      default: "Option 1"
    },
    option2: {
      type: String,
      default: "Option 2"
    },
  },
  data() {
    return {
      selectedOption: null,
    };
  },
  created() {
    this.selectedOption = this.toggleValue;
  },
  methods: {
    toggle() {
      this.selectedOption = !this.selectedOption;
      this.$emit('update', this.selectedOption );
    }
  }
};
</script>