export { modal };

import { uuidv4 } from "@/utils/Uuid";

var modal = {
  props: {
    id: {
      type: String,
      required: false,
      default: "modal-" + uuidv4(),
    },
  },
  data() {
    return {
      shown: false,
    };
  },
  mounted() {
    this.$bus.$on("open-modal", (id) => {
      if (id === this.id && !this.shown) this.openModal(id);
    });
  },
  methods: {
    openModal(id) {
      this.shown = true;
      const thisId = id ? id : this.id;
      $("#" + thisId).modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },
  },
};
