<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline class="col-xl-12" headline="Anmeldeformular" :col="3">
      </container-headline>

      <div class="row col-xl-12 mb-3">
        <div class="col-xl-12 block br-t-l-0">
          <div class="eingabe-panel-kopf p-3">
            <div class="row mb-3" v-if="lehrgang.bildungsgangtyp">
              <div class="col-xl-3">
                <div class="form-group">
                  <input
                    class="form-control"
                    :disabled="true"
                    v-model="lehrgang.bildungsgangtyp.bezeichnung"
                    readonly
                  />
                  <label class="">{{ $t("global.educationcoursetype") }}</label>
                </div>
              </div>
              <div class="col-xl-3" v-if="lehrgang.anmeldeformular">
                <div class="form-group">
                  <v-select
                    v-model="lehrgang.anmeldeformular.bestaetigungsmailvorlage"
                    :getOptionLabel="(vorlage) => vorlage.bezeichnung"
                    :options="mailvorlagen"
                    :clearable="true"
                    :disabled="!editable"
                    :placeholder="$t('global.mailtemplate')"
                  >
                    <span slot="no-options">{{
                      $t("global.taptosearch")
                    }}</span>
                  </v-select>
                  <label>{{
                    $t("global.registrationconfirmationmailtemplate")
                  }}</label>
                </div>
              </div>
              <!--     <div class="col-xl-3" v-if="lehrgang.anmeldeformular">
                <div class="form-group">
                  <v-select
                    v-model="lehrgang.anmeldeformular.wartelistemailvorlage"
                    :getOptionLabel="(vorlage) => vorlage.bezeichnung"
                    :options="mailvorlagen"
                    :clearable="true"
                    placeholder="Mailvorlage"
                  >
                    <span slot="no-options">{{
                      $t("global.taptosearch")
                    }}</span>
                  </v-select>
                  <label>{{ $t("global.waitinglistmailtemplate") }}</label>
                </div>
              </div> -->
            </div>

            <!-- STANDARDFELDER START -->
            <div class="row">
              <div
                class="col-12"
                v-for="(feld, index) in formularFelder"
                :key="'feld-' + index"
              >
                <div v-if="!feld.custom" class="d-flex align-items-center">
                  <!-- ANZEIGE -->
                  <div class="col-1 d-flex align-items-center">
                    <div class="form-check">
                      <input
                        v-model="feld.anzeige"
                        class="form-check-input"
                        type="checkbox"
                        :disabled="!editable"
                      />
                      <label class="form-check-label">{{
                        $t("global.display")
                      }}</label>
                    </div>
                  </div>
                  <!-- IST PFLICHTFELD -->
                  <div class="col-1 d-flex align-items-center">
                    <div class="form-check">
                      <input
                        v-model="feld.pflicht"
                        class="form-check-input"
                        type="checkbox"
                        :disabled="!editable"
                      />
                      <label class="form-check-label">{{
                        $t("global.mandatory")
                      }}</label>
                    </div>
                  </div>
                  <!-- BEZEICHNUNG -->
                  <div class="col-2">
                    <div class="form-group">
                      <input
                        :value="getFeldbezeichnung(feld.bezeichnung)"
                        class="form-control input"
                        type="text"
                        :placeholder="$t('global.designation')"
                        :class="{ 'border-danger': !feld.bezeichnung }"
                        :readonly="true"
                      />
                      <label :class="{ 'text-danger': !feld.bezeichnung }">{{
                        $t("global.designation")
                      }}</label>
                    </div>
                  </div>
                  <!-- EINGABETYP -->
                  <!-- <div class="col-2">
                  <div class="form-group">
                    <v-select
                      v-model="feld.eingabetyp"
                      :getOptionLabel="(typ) => typ.bezeichnung"
                      :options="eingabetypen"
                      :clearable="false"
                      :disabled="true"
                      placeholder="Eingabetyp"
                    >
                      <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                    </v-select>
                    <label>Eingabetyp</label>
                  </div>
                </div> -->
                  <!-- AUSWAHLTABELLE -->
                  <!-- <div class="col-2" v-if="feld.eingabetyp.appkey === 'dropdown'">
                  <div class="form-group">
                    <v-select
                      v-model="feld.linktable"
                      :getOptionLabel="(table) => table.bezeichnung"
                      :options="linktables"
                      placeholder="Auswahltabelle"
                    >
                      <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                    </v-select>
                    <label>Auswahltabelle</label>
                  </div>
                </div> -->
                  <!-- VERRECHNUNGSPOSITION -->
                  <div class="col-2" v-if="feld.eingabetyp.appkey === 'order'">
                    <div class="form-group">
                      <v-select
                        v-model="feld.verrechnungsposition"
                        :getOptionLabel="
                          (position) => position.artikel.bezeichnung
                        "
                        :options="verrechnungspositionen"
                        :placeholder="$t('global.clearingitems')"
                      >
                        <span slot="no-options">{{
                          $t("global.nodataavailable")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.clearingitems") }}</label>
                    </div>
                  </div>
                  <!-- REIHE ENTFERNEN -->
                  <!--   <div class="col-1 ml-auto">
                  <div class="form-group">
                    <button
                      class="btn btn-danger"
                      v-if="editable"
                      @click="removeRow(feld)"
                      v-tooltip.hover
                      title="Reihe entfernen"
                    >
                      <font-awesome-icon icon="fa-duotone fa-trash" />
                    </button>
                  </div>
                </div> -->
                </div>
                <!--    <div class="col-12 d-flex align-items-center">

                <div class="col-1 ml-auto">
                  <div class="form-group">
                    <button
                      class="btn btn-success"
                      v-if="editable"
                      @click="addRow()"
                      v-tooltip.hover
                      title="Reihe hinzufügen"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                    </button>
                  </div>
                </div>
              </div> -->
              </div>
            </div>
            <!-- STANDARDFELDER ENDE -->

            <!-- epic horizontal line -->
            <div v-if="lehrgang.anmeldeformular">
              <div><hr /></div>
              <h5>{{ $t("global.additionalregistrationinformation") }}</h5>
              <!-- CUSTOMFIELDS START -->
              <div class="row">
                <div
                  class="col-12"
                  v-for="(feld, index) in formularFelder"
                  :key="'feld-' + index"
                >
                  <div v-if="feld.custom" class="d-flex align-items-center">
                    <!-- ANZEIGE -->
                    <div class="col-1 d-flex align-items-center">
                      <div class="form-check">
                        <input
                          v-model="feld.anzeige"
                          class="form-check-input"
                          type="checkbox"
                          :disabled="!editable"
                        />
                        <label class="form-check-label">{{
                          $t("global.display")
                        }}</label>
                      </div>
                    </div>
                    <!-- IST PFLICHTFELD -->
                    <div class="col-1 d-flex align-items-center">
                      <div class="form-check">
                        <input
                          v-model="feld.pflicht"
                          class="form-check-input"
                          type="checkbox"
                          :disabled="!editable"
                        />
                        <label class="form-check-label">{{
                          $t("global.mandatory")
                        }}</label>
                      </div>
                    </div>
                    <!-- BEZEICHNUNG -->
                    <div class="col-4">
                      <div class="form-group">
                        <input
                          v-model="feld.bezeichnung"
                          class="form-control input"
                          type="text"
                          :placeholder="$t('global.designation')"
                          :class="{ 'border-danger': !feld.bezeichnung }"
                          :readonly="!editable"
                        />
                        <label :class="{ 'text-danger': !feld.bezeichnung }">{{
                          $t("global.designation")
                        }}</label>
                      </div>
                    </div>
                    <!-- EINGABETYP -->
                    <div class="col-2">
                      <div class="form-group">
                        <v-select
                          v-model="feld.eingabetyp"
                          :getOptionLabel="(typ) => typ.bezeichnung"
                          :options="eingabetypen"
                          :clearable="false"
                          :placeholder="$t('global.inputtype')"
                          :disabled="!editable || feld.hat_anmeldungen"
                          v-tooltip.hover="
                            (text = feld.hat_anmeldungen
                              ? $t(
                                  'global.cannotedittheresalreadyregistrations'
                                )
                              : '')
                          "
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.inputtype") }}</label>
                      </div>
                    </div>
                    <!-- VERRECHNUNGSPOSITION -->
                    <div
                      class="col-2"
                      v-if="feld.eingabetyp.appkey === 'order'"
                    >
                      <div class="form-group">
                        <v-select
                          v-model="feld.verrechnungsposition"
                          :getOptionLabel="
                            (position) => position.artikel.bezeichnung
                          "
                          :options="verrechnungspositionen"
                          :placeholder="$t('global.clearingitems')"
                          @input="displayArtikelBezeichnung(feld)"
                          :class="
                            !feld.verrechnungsposition ? 'is-invalid' : ''
                          "
                          :disabled="!editable || feld.hat_anmeldungen"
                          v-tooltip.hover="
                            (text = feld.hat_anmeldungen
                              ? $t(
                                  'global.cannotedittheresalreadyregistrations'
                                )
                              : '')
                          "
                        >
                          <span slot="no-options">{{
                            $t("global.nodataavailable")
                          }}</span>
                        </v-select>
                        <label
                          :class="{ 'text-danger': !feld.verrechnungsposition }"
                          >{{ $t("global.clearingitems") }}</label
                        >
                      </div>
                    </div>
                    <!-- SELECTION START -->
                    <div
                      class="col-2"
                      v-if="feld.eingabetyp.appkey === 'selection'"
                    >
                      <div class="form-group">
                        <div
                          class="d-flex align-items-center"
                          role="button"
                          @click="
                            !feld.selectiongroup
                              ? openSelectionConfigModal(feld)
                              : editSelectionConfigModal(feld)
                          "
                        >
                          <font-awesome-icon
                            icon="fa-regular fa-pencil"
                            size="sm"
                          />
                          <template v-if="feld.selectiongroup">
                            <font-awesome-icon
                              icon="fa-solid fa-check"
                              size="sm"
                              class="ml-2"
                            />
                          </template>
                        </div>
                        <label>{{ $t("global.selectionconfiguration") }}</label>
                      </div>
                    </div>
                    <!-- SELECTION END -->
                    <!-- REIHE ENTFERNEN -->
                    <div class="col-1 ml-auto">
                      <div class="form-group">
                        <button
                          class="btn btn-danger"
                          v-if="editable"
                          @click="removeRow(feld)"
                          :disabled="!editable || feld.hat_anmeldungen"
                          v-tooltip.hover="
                            (text = feld.hat_anmeldungen
                              ? $t(
                                  'global.cannotedittheresalreadyregistrations'
                                )
                              : 'Reihe entfernen')
                          "
                        >
                          <font-awesome-icon icon="fa-duotone fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex align-items-center">
                  <div class="col-1 mr-auto">
                    <div class="form-group">
                      <button
                        class="btn btn-success"
                        v-if="editable"
                        @click="addRow()"
                        v-tooltip.hover
                        title="Reihe hinzufügen"
                      >
                        <font-awesome-icon icon="fa-regular fa-plus" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- CUSTOMFIELDS ENDE -->
          </div>
        </div>
      </div>
    </div>
    <selection-config-modal
      id="selection-config-modal"
      @confirmed="saveSelectionConfig"
    >
    </selection-config-modal>
  </div>
</template>

<script>
import Api from "@/Api";
import ContainerHeadline from "@/components/ContainerHeadline";
import SelectionConfigModal from "@/components/Modals/SelectionConfigModal.vue";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import reiter from "@/mixins/Reiter";

export default {
  name: "Anmeldeformular",
  components: {
    ContainerHeadline,
    SelectionConfigModal,
  },
  mixins: [reiter],
  props: {
    lehrgang: {
      type: Object,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
    mailvorlagen: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      //ToDo: verrechnungspositionen aus Lehrgang holen
      //verrechnungspositionen: [],
      auswahltabellen: [],
      //ToDo: Welche ID soll hier verwendet werden?
      linktables: [
        { id: "173EBD01DAB", bezeichnung: this.$t("global.salutation") }, //aktuell id = bxc_geschlecht tableID
      ],

      verrechnungspositionen: [],
      verrechnungsPositionenLoaded: false,
    };
  },
  watch: {
    shown(val) {
      if (val) this.getVerrechnungspositionen();
    },
    lehrgang: {
      //  immediate: true, // Aufruf der Handler-Funktion sofort beim Erstellen der Komponente
      deep: true, // Überwacht auch Änderungen in verschachtelten Eigenschaften
      handler(newLehrgang) {
        // Überprüfen, ob die lehrgang-Prop und ihre Eigenschaften vorhanden sind
        if (
          newLehrgang &&
          newLehrgang.id &&
          this.shown &&
          !this.verrechnungsPositionenLoaded
        ) {
          this.getVerrechnungspositionen();
          this.verrechnungsPositionenLoaded = true;
        }
      },
    },
  },
  mounted() {},
  computed: {
    eingabetypen() {
      return [
        // { appkey: "dropdown", bezeichnung: "Einfachauswahl" },
        {
          id: "18964031938",
          appkey: "singleline",
          bezeichnung: this.$t("global.singlelinetext"),
        },
        {
          id: "1896403B994",
          appkey: "multiline",
          bezeichnung: this.$t("global.multilinetext"),
        },
        {
          id: "1896405F16F",
          appkey: "order",
          bezeichnung: this.$t("global.clearingitems"),
        },
        // { appkey: "phone", bezeichnung: "Telefonnummer" },
        // { appkey: "email", bezeichnung: "E-Mail" },
        // {
        //   appkey: "socialsecuritynumber",
        //   bezeichnung: "Sozialversicherungsnummer",
        // },
        {
          id: "1896407F9F7",
          appkey: "date",
          bezeichnung: this.$t("global.date"),
        },
        {
          id: "18964082A32",
          appkey: "checkbox",
          bezeichnung: this.$t("global.checkbox"),
        },
        {
          id: "18C3A1A6EEB",
          appkey: "selection",
          bezeichnung: this.$t("global.selectiontype"),
        },
        // { appkey: "file", bezeichnung: "Dokument" },
      ];
    },
    formularFelder() {
      if (this.lehrgang.anmeldeformular)
        return this.lehrgang.anmeldeformular.formularfelder;
      else return [];
    },
  },
  created() {},
  methods: {
    getVerrechnungspositionen() {
      if (this.lehrgang.id) {
        var queryParams = {};

        this.isTemplate
          ? (queryParams.templateLehrgang = this.lehrgang.id)
          : (queryParams.lehrgang = this.lehrgang.id);

        Api.get("/rechnungslegung/verrechnungsposition/", {
          params: queryParams,
        }).then((response) => {
          this.verrechnungspositionen = response.data;
        });
      }
    },
    getFeldbezeichnung(bezeichnung) {
      return this.$t("portal." + bezeichnung);
    },
    removeRow(feld) {
      if (this.lehrgang.anmeldeformular) {
        this.lehrgang.anmeldeformular.formularfelder =
          this.lehrgang.anmeldeformular.formularfelder.filter((thisFeld) => {
            return thisFeld.id !== feld.id;
          });
        // this.sortAfterDelete(feld.sortierung, false);
      }
      // else if (this.lehrgang.templateAnmeldeformular) {
      //   this.lehrgang.templateAnmeldeformular.templatefelder =
      //     this.lehrgang.templateAnmeldeformular.templatefelder.filter(
      //       (thisFeld) => {
      //         return thisFeld.id !== feld.id;
      //       }
      //     );
      //   //  this.sortAfterDelete(feld.sortierung, true);
      // }
    },
    addRow() {
      if (this.lehrgang.anmeldeformular) {
        this.lehrgang.anmeldeformular.formularfelder.push({
          id: Date.now().toString(10),
          anzeige: null,
          pflicht: null,
          bezeichnung: null,
          eingabetyp: {
            id: "18964031938",
            appkey: "singleline",
            bezeichnung: this.$t("global.singlelinetext"),
          },
          verrechnungsposition: null,
          custom: true,
        });
      }
      // else if (this.lehrgang.templateAnmeldeformular) {
      //   this.lehrgang.templateAnmeldeformular.templatefelder.push({
      //     id: Date.now().toString(10),
      //     anzeige: null,
      //     pflicht: null,
      //     bezeichnung: null,
      //     eingabetyp: {
      //       id: "18964031938",
      //       appkey: "singleline",
      //       bezeichnung: "Einzeiliger Text",
      //     },
      //     verrechnungsposition: null,
      //     custom: true,
      //   });
      // }
    },
    displayArtikelBezeichnung(feld) {
      if (feld.bezeichnung) return;
      feld.bezeichnung = feld.verrechnungsposition
        ? feld.verrechnungsposition.artikel.bezeichnung
        : null;
    },
    openSelectionConfigModal(feld) {
      this.$bus.$emit(
        "openSelectionConfigModal",
        "selection-config-modal",
        feld
      );
    },
    editSelectionConfigModal(feld) {
      this.$bus.$emit(
        "editSelectionConfigModal",
        "selection-config-modal",
        feld
      );
    },
    saveSelectionConfig(feld, json) {
      if (this.loading) return;

      if (!json.id) {
        // POST
        Api.post("lehrgaenge/selectiongroup/", json)
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.selectionconfigsavedsuccessfully"),
            });
            this.$set(feld, "selectiongroup", response.data);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        // PUT
        Api.put("lehrgaenge/selectiongroup/", json, { params: { id: json.id } })
          .then((response) => {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.selectionconfigsavedsuccessfully"),
            });
            this.$set(feld, "selectiongroup", response.data);
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    // Anpassung der Sortierung nachdem eine Row gelöscht wird
    /*     sortAfterDelete(delNum, template) {
      if (template) {
        this.lehrgang.templateAnmeldeformular.templatefelder
          .filter((feld) => {
            return feld.sortierung > delNum;
          })
          .forEach((feld) => (feld.sortierung -= 1));
      } else {
        this.lehrgang.anmeldeformular.formularfelder
          .filter((feld) => {
            return feld.sortierung > delNum;
          })
          .forEach((feld) => (feld.sortierung -= 1));
      }
    }, */
  },
};
</script>

<style scoped></style>
