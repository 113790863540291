<template>
  <div class="form-group">
    <span v-if="editable" class="editIcon" v-on:click="oeffneGPSucheModal"
      ><font-awesome-icon icon="fa-duotone fa-pencil-alt"
    /></span>
    <span
      v-if="allowOpen && personId != null"
      class="openIcon"
      v-on:click="openPerson"
      ><font-awesome-icon icon="fa-duotone fa-arrow-circle-right"
    /></span>
    <ValidationProvider
      :rules="required ? 'required' : ''"
      immediate
      v-slot="{ passed }"
      ><input v-model="personId" type="hidden" />
      <b-form-input
        v-model="personName"
        v-bind:class="{ 'border-danger': !passed }"
        readonly
        placeholder=" "
      />
      <label v-bind:class="{ 'text-danger': !passed }">{{ label }}</label>
    </ValidationProvider>

    <gpSucheModal
      :id="modalId"
      :filterRollen="rollen"
      @confirmed="onGPResult"
      @updatefirma="onFirma"
      :filterPerson="person"
      :activeonly="activeonly"
      :filterFirma="setFirma"
      remove
    ></gpSucheModal>
  </div>
</template>

<script>
import gpSucheModal from "@/components/Geschaeftspartner/gpSucheModal";
import { v4 as uuid } from "uuid";

export default {
  name: "SingleSelectPerson",
  components: { gpSucheModal },
  props: {
    id: {
      type: String,
      default: uuid(),
    },
    person: {
      type: Object,
      required: false,
      default: function () {
        return { personName: this.$t("global.notlinked") };
      },
    },
    firma: {
      type: Object,
      required: false,
      default: function () {
        return { name: this.$t("global.notlinked") };
      },
    },
    allowOpen: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    rollen: {
      type: Array,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    activeonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    modalId() {
      return "geschaeftspartner-modal-" + this.id + uuid();
    },
    personId() {
      return this.person == null ? null : this.person.id;
    },
    personName() {
      return this.person == null
        ? this.$t("global.notlinked")
        : this.person?.id
        ? this.person.personName
        : this.$t("global.notlinked");
    },
    setFirma() {
      return this.firma || { name: this.$t("global.notlinked") };
    },
  },
  methods: {
    oeffneGPSucheModal() {
      this.$bus.$emit("openGeschaeftspartnerModal", this.modalId);
    },

    onGPResult(selectedPerson) {
      if (selectedPerson) {
        this.$emit(
          "confirmed",
          selectedPerson[0] ? selectedPerson[0] : selectedPerson
        );
      }
    },

    onFirma(firma) {
      this.$emit("updatefirma", firma);
    },

    openPerson() {
      if (this.person.id) {
        this.$router.push({
          name: "geschaeftspartner",
          params: { id: this.person.id, anzeige: 0 },
        });
      }
    },
  },
};
</script>
