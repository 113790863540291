export const DOCS_BASE_URL = "https://www.bx-education.ch/docs/erp/";

export default {
  data() {
    return {
      noHelpURL: DOCS_BASE_URL,
      helpUrlMap: {
        Geschaeftspartner: DOCS_BASE_URL + "crm/#personen",
        "personen-liste": DOCS_BASE_URL + "crm/#personen",
        Firmenliste: DOCS_BASE_URL + "crm/#Unternehmen",
        Firma: DOCS_BASE_URL + "crm/#Unternehmen",
        Bildungsgang: DOCS_BASE_URL + "ausbildung/#Bildungsgang",
        Klasse: DOCS_BASE_URL + "ausbildung/#Klasse",
        Bildungsliste: DOCS_BASE_URL + "ausbildung/#Bildungsgang",
        Klassenliste: DOCS_BASE_URL + "ausbildung/#Klasse",
        Fach: DOCS_BASE_URL + "ausbildung/#Fächer",
        "faecher-liste": DOCS_BASE_URL + "ausbildung/#Fächer",
        Korrespondenzliste: DOCS_BASE_URL + "kommunikation/#Korrespondenz",
        Korrespondenz: DOCS_BASE_URL + "kommunikation/#Korrespondenz",
        Mailliste: DOCS_BASE_URL + "kommunikation/#Email",
        Mail: DOCS_BASE_URL + "kommunikation/#Email",
        Dokumentenliste: DOCS_BASE_URL + "kommunikation/#Dokumente",
        Dokument: DOCS_BASE_URL + "kommunikation/#Dokumente",
        NotizListe: DOCS_BASE_URL + "kommunikation/#Notizen",
        Notiz: DOCS_BASE_URL + "kommunikation/#Notizen",
      },
    };
  },
};
