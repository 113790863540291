export const SET_EMAIL_TYPEN = "setEmailTypen";
export const SET_BONITAETEN = "setBonitaeten";
export const SET_GESCHLECHTER = "setGeschlechter";
export const SET_FUNKTIONSBEREICHE = "setFunktionsbereiche";
export const SET_ANREDEN = "setAnreden";
export const SET_AKADEMISCHER_TITEL = "setAkademischerTitel";
export const SET_INTERESSEN = "setInteressen";
export const SET_ZIVILSTAENDE = "setZivilstaende";
export const SET_NATIONALITAETEN = "setNationalitaeten";
export const SET_SPRACHEN = "setSprachen";
export const SET_AUFENTHALTSBEWILLIGUNGEN = "setAufenthaltsbewilligung";
export const SET_ANSTELLLUNGS_ARTEN = "setAnstellungsArten";
export const SET_AKTIVITAETS_STATI = "setAktivitaetsStati";
export const SET_KANTONE = "setKantone";
export const SET_PARTNERROLLEN = "setPartnerrollen";
export const SET_DOZENTEN = "setDozenten";
export const SET_QUALIFIKATION_TYPEN = "setQualifikationTypen";
export const SET_QUALIFIKATION_NIVEAU = "setQualifikationNiveau";
export const SET_POSTLEITZAHLEN = "setPostleitzahlen";
export const SET_ADRESS_VERWENDUNG_TYPEN = "setAdressVerwendungTypen";
export const SET_RELATION_TYPEN = "setRelationTypen";
export const SET_RELATION_ARTEN = "setRelationArten";
export const SET_RELATION_STATI = "setRelationStati";
export const SET_FIRMA_PARTNERROLLEN = "setFirmaPartnerrollen";
export const SET_BRANCHEN = "setBranchen";
export const SET_KOMPETENZSTUFEN = "setKompetenzstufen";
export const SET_UNTERRICHTSSPRACHEN = "setUnterrichtssprachen";
export const SET_EINREICHUNGSTATI = "setEinreichungstati";
export const SET_KONKORDATE = "setKonkordate";
export const SET_INSTITUTIONEN = "setInstitutionen";
