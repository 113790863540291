<template>
  <div v-if="specificFields.length != 0">
    <container-headline
      :headline="$t('global.moreinformation')"
      :col="6"
    ></container-headline>

    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <!-- mandantenspezifische Felder für diese Entität darstellen -->

            <div
              v-for="(datenfeld, index) in specificFields"
              :key="datenfeld.bezeichnung + '-' + index"
              :class="
                datenfeld.eingabetyp.appkey == 'multline' ||
                datenfeld.eingabetyp.appkey == 'editor'
                  ? 'col-xl-12'
                  : 'col-xl-6'
              "
            >
              <!-- Für Eingabetyp Einfachauswahl -->
              <div v-if="datenfeld.eingabetyp.appkey == 'dropdown'">
                <div class="form-group">
                  <v-select
                    :readonly="!editable"
                    v-model="datenfeld.value"
                    :options="datenfeld.auswahlwerte"
                    :reduce="(v) => v.id"
                    :getOptionLabel="(feld) => feld.uebersetzung"
                    :placeholder="
                      datenfeld.uebersetzung || datenfeld.bezeichnung
                    "
                  >
                  </v-select>
                  <label>{{
                    datenfeld.uebersetzung || datenfeld.bezeichnung
                  }}</label>
                </div>
              </div>
              <!-- Für Eingabetyp Mehrfachauswahl (momentan nur überschreiben)
                                TODO: entfernen und hinzufügen muss noch ergänzt werden  -->
              <div v-if="datenfeld.eingabetyp.appkey == 'dropdown-multi'">
                <div class="form-group">
                  <v-select
                    v-model.trim="datenfeld.value"
                    :options="datenfeld.auswahlwerte"
                    :reduce="(v) => v.id"
                    :getOptionLabel="(feld) => feld.uebersetzung"
                    :placeholder="
                      datenfeld.uebersetzung || datenfeld.bezeichnung
                    "
                    multiple
                  >
                  </v-select>
                  <label>{{
                    datenfeld.uebersetzung || datenfeld.bezeichnung
                  }}</label>
                </div>
              </div>
              <!-- Für Eingabetyp Checkbox -->
              <div v-if="datenfeld.eingabetyp.appkey == 'checkbox'">
                <div class="form-check">
                  <input
                    :disabled="!editable"
                    v-model="datenfeld.value"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label">{{
                    datenfeld.uebersetzung || datenfeld.bezeichnung
                  }}</label>
                </div>
              </div>
              <!-- Für Eingabetyp Integer -->
              <div v-if="datenfeld.eingabetyp.appkey == 'integer'">
                <div class="form-group">
                  <input
                    :disabled="!editable"
                    v-model.number="datenfeld.value"
                    class="form-control"
                    :placeholder="
                      datenfeld.uebersetzung || datenfeld.bezeichnung
                    "
                  />
                  <label>{{
                    datenfeld.uebersetzung || datenfeld.bezeichnung
                  }}</label>
                </div>
              </div>
              <!-- Für Eingabetyp Datum -->
              <div v-if="datenfeld.eingabetyp.appkey == 'date'">
                <div class="form-group">
                  <date-picker
                    :disabled="!editable"
                    date-format="dd.mm.yy"
                    placeholder=""
                    :show-icon="true"
                    @update="(val) => (datenfeld.value = val)"
                  />
                  <label>{{
                    datenfeld.uebersetzung || datenfeld.bezeichnung
                  }}</label>
                </div>
              </div>
              <!-- Für Eingabetyp Einzeiliger Text -->
              <div v-if="datenfeld.eingabetyp.appkey == 'singleline'">
                <div class="form-group">
                  <input
                    :disabled="!editable"
                    v-model="datenfeld.value"
                    class="form-control"
                    :placeholder="
                      datenfeld.uebersetzung || datenfeld.bezeichnung
                    "
                  />
                  <label>{{
                    datenfeld.uebersetzung || datenfeld.bezeichnung
                  }}</label>
                </div>
              </div>
              <!-- Für Eingabetyp Mehrzeiliger Text -->
              <div v-if="datenfeld.eingabetyp.appkey == 'multiline'">
                <div class="form-group">
                  <textarea
                    :disabled="!editable"
                    v-model="datenfeld.value"
                    class="form-control"
                    :placeholder="
                      datenfeld.uebersetzung || datenfeld.bezeichnung
                    "
                    rows="3"
                    style="resize: both"
                  ></textarea>
                  <label>{{
                    datenfeld.uebersetzung || datenfeld.bezeichnung
                  }}</label>
                </div>
              </div>
              <!-- Für Eingabetyp CkEditor -->
              <div v-if="datenfeld.eingabetyp.appkey == 'ckeditor'">
                <div class="form-group">
                  <ckeditor
                    :disabled="!editable"
                    :editor="editor"
                    v-model="datenfeld.value"
                  />
                  <label>{{
                    datenfeld.uebersetzung || datenfeld.bezeichnung
                  }}</label>
                </div>
              </div>

              <!-- Für Eingabetyp datetime -->
              <div
                v-if="datenfeld.eingabetyp.appkey == 'datetime'"
                class="d-flex"
              >
                <div class="form-group pr-5">
                  <date-picker
                    :disabled="!editable"
                    class="minw-150-px"
                    date-format="dd.mm.yy"
                    placeholer=" "
                    :show-icon="true"
                    :initial="getDateString(datenfeld.value)"
                    @update="(val) => setDateInput(datenfeld, val)"
                  />
                  <label>{{
                    datenfeld.uebersetzung
                      ? datenfeld.uebersetzung + " - " + $t("global.date")
                      : datenfeld.bezeichnung + " - " + $t("global.date")
                  }}</label>
                </div>

                <div class="form-group">
                  <div class="input-group">
                    <input
                      :disabled="!editable || !datenfeld.value"
                      type="time"
                      class="form-control minw-150-px"
                      :value="getTimeString(datenfeld.value)"
                      @input="setTimeInput(datenfeld, $event)"
                      v-tooltip
                      :title="
                        !datenfeld.value
                          ? $t('global.selectdatebeforetimeinput')
                          : ''
                      "
                    />
                    <div
                      class="input-group-append text-medium-light h-100 mr-2 d-flex align-items-center"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-clock"
                        class="m-auto"
                      />
                    </div>
                  </div>
                  <label>{{
                    datenfeld.uebersetzung
                      ? datenfeld.uebersetzung + " - " + $t("global.TIme")
                      : datenfeld.bezeichnung + " - " + $t("global.TIme")
                  }}</label>
                </div>
              </div>
            </div>
            <!-- End of loop -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";

export default {
  name: "Kundenspezifische-Felder",
  components: {
    ContainerHeadline,

    DatePicker,
  },
  mixins: [],
  props: {
    target: {
      type: Object,
      default: function () {
        return {};
      },
    },
    editable: {
      type: Boolean,
      required: true,
    },
    /*   specificFields: {
      type: Array,
      default: function () {
        return [];
      },
    }, */
  },
  data() {
    return {
      // temporär, bis Felder als prop rein kommen
      specificFields: [],
      //   {
      //     bezeichnung: "Singleline",
      //     interne_bezeichnung: "singleline-feld-Interne-Bezeichnung",
      //     uebersetzung: "Singleline field",
      //     eingabetyp: {
      //       appkey: "singleline",
      //     },
      //     value: null,
      //   },
      //   {
      //     bezeichnung: "Dropdown",
      //     interne_bezeichnung: "dropdownfeld-interne-bezeichnung",
      //     uebersetzung: "Dropdown field",
      //     eingabetyp: {
      //       appkey: "dropdown",
      //     },
      //     auswahlwerte: [
      //       {
      //         id: "tg7312tvdew",
      //         bezeichnung: "Wert 1",
      //         uebersetzung: "Wert 1",
      //       },
      //       {
      //         id: "asdsadqwf12",
      //         bezeichnung: "Wert 2",
      //         uebersetzung: "Wert 2",
      //       },
      //       {
      //         id: "tg7sasdasd12tvdew",
      //         bezeichnung: "Wert 3",
      //         uebersetzung: "Wert 3",
      //       },
      //     ],
      //     value: null,
      //   },
      //   {
      //     bezeichnung: "Dropdown multi",
      //     interne_bezeichnung: "dropdownfeld-interne-bezeichnung",
      //     uebersetzung: "Dropdown field multi",
      //     eingabetyp: {
      //       appkey: "dropdown-multi",
      //     },
      //     auswahlwerte: [
      //       {
      //         id: "tg7312tvdew",
      //         bezeichnung: "Wert 1",
      //         uebersetzung: "Wert 1",
      //       },
      //       {
      //         id: "asdsadqwf12",
      //         bezeichnung: "Wert 2",
      //         uebersetzung: "Wert 2",
      //       },
      //       {
      //         id: "tg7sasdasd12tvdew",
      //         bezeichnung: "Wert 3",
      //         uebersetzung: "Wert 3",
      //       },
      //     ],
      //     value: null,
      //   },
      //   {
      //     bezeichnung: "Multiline",
      //     interne_bezeichnung: "multiilne-interne-Bezeichnung",
      //     uebersetzung: "Multiline field",
      //     eingabetyp: {
      //       appkey: "multiline",
      //     },
      //     value: null,
      //   },
      //   {
      //     bezeichnung: "Checkbox",
      //     interne_bezeichnung: "checkbox-interne-Bezeichnung",
      //     uebersetzung: "Checkbox field",
      //     eingabetyp: {
      //       appkey: "checkbox",
      //     },
      //     value: null,
      //   },
      //   {
      //     bezeichnung: "Date",
      //     interne_bezeichnung: "date-interne-Bezeichnung",
      //     uebersetzung: "Date field",
      //     eingabetyp: {
      //       appkey: "date",
      //     },
      //     value: null,
      //   },
      //   {
      //     bezeichnung: "Datetime",
      //     interne_bezeichnung: "datetime-interne-Bezeichnung",
      //     uebersetzung: "",
      //     eingabetyp: {
      //       appkey: "datetime",
      //     },
      //     value: "",
      //   },
      // ],
      dateTimes: [],
    };
  },
  computed: {
    /*     initialDateTimeValue() {
      return (feldBez) => {
        const dateTime = this.dateTimes.find(
          (item) => item.feldBez === feldBez
        );
        return dateTime
          ? { date: dateTime.date, time: dateTime.time }
          : { date: null, time: null };
      };
    }, */
  },
  created() {},
  watch: {},
  mounted() {},
  methods: {
    setDateInput(feld, val) {
      const parts = feld.value.split(" ");
      parts[0] = val;
      if (parts.length > 1) {
        feld.value = parts[0] + " " + parts[1];
      } else {
        feld.value = parts[0];
      }
    },
    setTimeInput(feld, event) {
      const parts = feld.value.split(" ");
      parts[1] = event.target.value;
      feld.value = parts[0] + " " + parts[1];
    },
    getDateString(dateTimeString) {
      if (dateTimeString) {
        return dateTimeString.split(" ")[0];
      }
    },
    getTimeString(dateTimeString) {
      if (dateTimeString) {
        return dateTimeString.split(" ")[1];
      }
    },
  },
};
</script>

<style></style>
