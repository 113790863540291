<template>
  <message-box
    :id="id"
    :headerText="$t('global.pdfviewer')"
    ref="msgBoxPdf"
    size="fluid"
    :cancel-text="$t('global.close')"
    no-footer
    @cancel="messageboxClosed"
  >
    <template #header>
      <div class="w-100">
        <div v-if="isMobile" class="row d-none d-md-flex">
          <div class="col-md-3 text-left d-none d-md-block">
            <button
              class="btn btn-light"
              v-tooltip="'PDF nach links drehen'"
              @click="rotateLeft"
            >
              <font-awesome-icon icon="fa-solid fa-undo" />
            </button>
            <button
              class="btn btn-light ml-1"
              v-tooltip="'PDF nach rechts drehen'"
              @click="rotateRight"
            >
              <font-awesome-icon icon="fa-solid fa-undo" flip="horizontal" />
            </button>
          </div>

          <div class="text-center fs-18 font-weight-bold col-6">
            <button
              class="btn btn-light mr-3"
              :disabled="!hasPreviousPage"
              @click="previousPage"
            >
              <font-awesome-icon icon="fa-solid fa-circle-arrow-left" />
            </button>
            <span>Seite {{ pdfPage }} von {{ pdfPages }}</span>
            <button
              class="btn btn-light ml-3"
              :disabled="!hasNextPage"
              @click="nextPage"
            >
              <font-awesome-icon icon="fa-solid fa-circle-arrow-right" />
            </button>
          </div>
          <div class="col-md-3 text-right d-none d-md-block">
            <button class="btn btn-light" @click="hide">
              <font-awesome-icon icon="fa-solid fa-ban" />
            </button>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-2 text-right"></div>
          <div class="col-10 text-right">
            <button class="btn btn-light" @click="hide">
              <font-awesome-icon icon="fa-solid fa-ban" />
            </button>
          </div>
        </div>
      </div>
    </template>
    <div v-if="isMobile">
      <div class="pdf-wrapper">
        <pdf
          v-if="filePath && open"
          v-show="loadingProgress === 1"
          :key="pdfKey"
          :src="filePath"
          :page="pdfPage"
          :rotate="rotation"
          @num-pages="(n) => (pdfPages = n)"
          @loaded="loaded = true"
          @progress="(n) => (loadingProgress = n)"
        ></pdf>
        <div v-show="loadingProgress < 1" class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: progressPercent + '%' }"
            :aria-valuenow="progressPercent"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ loadingProgress | percentage }}
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-2 col-lg-3 col-1 d-none d-md-flex"></div>
        <div class="col-3 col-md-2 col-lg-1 text-md-right text-left">
          <button
            class="btn btn-light mr-3"
            :disabled="!hasPreviousPage"
            @click="previousPage"
          >
            <font-awesome-icon icon="fa-solid fa-circle-arrow-left" />
          </button>
        </div>
        <div class="text-center fs-18 font-weight-bold col-6 col-md-4">
          Seite {{ pdfPage }} von {{ pdfPages }}
        </div>
        <div class="col-3 col-md-2 col-lg-1 text-md-left text-right">
          <button
            class="btn btn-light ml-3"
            :disabled="!hasNextPage"
            @click="nextPage"
          >
            <font-awesome-icon icon="fa-solid fa-circle-arrow-right" />
          </button>
        </div>
        <div class="col-md-2 col-lg-3 text-right d-none d-md-block"></div>
      </div>
    </div>
    <div v-else>
      <div class="row" :key="fileDisplay">
        <div
          class="col-1 text-left pt-2 pl-0 ml-0"
          v-if="multipleFiles === true"
        >
          <ul style="font-size: 10pt">
            <li
              v-for="(item, index) in internalFiles"
              v-bind:key="`file-${index}`"
              @click="setFile(item.url)"
            >
              <span v-if="!isNaN(item.size)" style="cursor: pointer"
                >{{ index * 100 + 1 }} - {{ index * 100 + item.size }}
              </span>
              <span v-if="isNaN(item.size)" style="cursor: pointer"
                >{{ item.size ? item.size : "Name not found" }}
              </span>
            </li>
          </ul>
          <div class="d-flex">
            <div
              class="btn btn-sm btn-success ml-3 w-100 ws-break-spaces"
              @click="downloadAll"
            >
              <font-awesome-icon
                class="mr-1"
                icon="fa-duotone fa-file-zipper"
              />
              {{ $t("global.downloadAsZip") }}
            </div>
          </div>
        </div>
        <div
          class="col-1 text-left pt-2 pl-0 ml-0"
          v-if="multipleFiles == false"
        ></div>
        <div class="col-11">
          <iframe
            v-if="open"
            :src="iFrameUrl"
            :page="pdfPage"
            width="100%"
            :height="heightPixel"
            @load="loaded = true"
          ></iframe>
        </div>
      </div>
      <loading-overlay v-if="loading"></loading-overlay>
    </div>
  </message-box>
</template>

<script>
import Api from "@/Api";
import { v4 as uuid } from "uuid";
import pdf from "vue-pdf";
import MessageBox from "@/components/Modals/Messagebox";
import LoadingOverlay from "@/components/global/LoadingOverlay";
import FileDownload from "js-file-download";

export default {
  name: "PdfLayer",
  components: {
    pdf,
    MessageBox,
    LoadingOverlay,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    src: {
      default: null,
    },
    files: [],
  },
  data() {
    return {
      pdfKey: uuid(),
      filePath: null,
      multipleFiles: false,
      pdfPage: 1,
      pdfPages: 0,
      loadingProgress: 0,
      loaded: false,
      open: false,
      rotation: 0,
      fileDisplay: uuid(),
      internalFiles: [],
      loading: false,
      interval: null,
      zipFile: {},
    };
  },
  watch: {
    files: function (val) {
      if (val && val.length > 0) {
        this.loading = val.length !== val[0].count;

        this.internalFiles = [...val]; // Copy des Arrays machen
        this.internalFiles.sort((a, b) => {
          return a.index - b.index;
        });

        this.filePath = this.internalFiles[0];
        this.multipleFiles = true;
      } else {
        this.multipleFiles = false;
        this.loading = true;
      }

      this.fileDisplay = uuid();
    },
    src: {
      deep: true,
      handler: function (val) {
        if (val && val.url !== "") {
          this.filePath = val;
          this.resetPdf();
        }
      },
    },
  },
  computed: {
    progressPercent() {
      return this.loadingProgress * 100;
    },
    hasPreviousPage() {
      return this.page > 1;
    },
    hasNextPage() {
      return this.page < this.pdfPages;
    },
    iFrameUrl() {
      if (!this.filePath) return null;

      if (typeof this.filePath === "object") {
        return this.filePath.url;
      } else if (typeof this.filePath === "string") {
        return this.filePath;
      } else {
        return null;
      }
    },
    height() {
      return window.innerHeight;
    },
    heightPixel() {
      return this.height - 230 + "px";
    },
  },
  methods: {
    setFile(url) {
      this.filePath = url;
    },
    show() {
      this.$refs.msgBoxPdf.openModal();
      this.open = true;
    },
    hide() {
      this.$refs.msgBoxPdf.closeModal();
      this.open = false;
    },
    messageboxClosed() {
      this.resetPdf();
      this.open = false;
    },
    resetPdf() {
      this.pdfPages = 0;
      this.pdfPage = 1;
      this.loadingProgress = 0;
      this.rotation = 0;
      this.pdfKey = uuid();
      this.fileDisplay = uuid();
      this.loading = false;
    },
    nextPage() {
      this.page++;
    },
    previousPage() {
      this.page--;
    },
    rotateLeft() {
      if (this.rotation === 0) this.rotation = 270;
      else this.rotation -= 90;
    },
    rotateRight() {
      if (this.rotation === 270) this.rotation = 0;
      else this.rotation += 90;
    },
    downloadAll() {
      let urls = [];

      this.internalFiles.forEach((file) => {
        urls.push(file.url);
      });

      //this.interval = setInterval(this.downloadItem, 300, urls);

      /*this.download(urls);*/
      //var json = Object.assign({}, this.internalFiles);

      Api.post("generateZip/", urls, { responseType: "blob" }).then(
        (response) => {
          this.zipFile = new Blob([response.data], {
            type: "application/zip",
          });
          FileDownload(this.zipFile, `BXEducation_export.zip`);
        }
      );
    },
    download(urls) {
      urls.forEach((url) => {
        let iframe = document.createElement("iframe");
        iframe.style.visibility = "collapse";
        document.body.append(iframe);

        iframe.contentDocument.write(
          `<form action="${url.replace(/"/g, '"')}" method="GET"></form>`
        );
        iframe.contentDocument.forms[0].submit();

        setTimeout(() => iframe.remove(), 2000);
      });
    },
    /*
    download(urls) {
      var url = urls.pop();

      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", "test.pdf");
      a.setAttribute("target", "_blank");
      a.click();

      if (urls.length == 0) {
        clearInterval(this.interval);
      }
    },*/
    /*
    downloadItem(urls) {
      var url = urls.pop();

      Api.get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error)
        .finally(() => {
          close();
        });

      if (urls.length == 0) {
        clearInterval(this.interval);
      }
    },*/
  },
};
</script>

<style lang="scss" scoped>
.progress {
  margin-top: 3rem;
  margin-bottom: 3rem;
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translateX(50%);
  width: 50%;
}

.pdf-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  background-color: white;
}
</style>
