<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :docsName="docsName"/>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>

              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4">
                  <button
                    v-if="berechtigungen.b_bildung_noteneingabe.update"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    {{ $t("global.save") }}
                  </button>
                  <button
                    v-show="isDozent || isAdmin"
                    class="btn btn-primary mr-2"
                    :disabled="
                      invalid ||
                      noteneingabe.noteneingabe_status.id ==
                        $CONST('NOTENEINGABESTATI').COMPLETED
                    "
                    @click="noteneingabeAbschliessen"
                  >
                    {{ $t("global.completegradeentry") }}
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2"/>
                    {{
                      $t("global.tolist")
                    }}
                  </button>
                </div>
                <div class="mr-4">
                  <button
                    class="btn btn-danger"
                    @click="oeffneLoeschenModal"
                    v-if="
                      berechtigungen.b_bildung_noteneingabe.delete && editable
                    "
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="fa-duotone fa-trash"
                    />
                    <span>{{ $t("global.delete") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Daten -->
          <container-headline
            :headline="$t('global.masterdata')"
            :col="4"
          ></container-headline>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-8">
                    <div class="form-group">
                      <input
                        v-model="noteneingabe.bezeichnung"
                        :readonly="!editable || isDozent"
                        class="form-control"
                        tabindex="0"
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group" tabindex="1">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <v-select
                          v-model="noteneingabe.noteneingabe_typ"
                          label="bezeichnung"
                          :options="noteneingabetypen"
                          :disabled="!editable || isDozent"
                          :placeholder="$t('global.scoreinputtype')"
                        >
                          <span slot="no-options">{{
                              $t("global.taptosearch")
                            }}</span>
                        </v-select>
                        <label v-bind:class="{ 'text-danger': !passed }">{{
                            $t("global.scoreinputtype")
                          }}</label>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group" tabindex="2">
                      <v-select
                        v-model="noteneingabe.noteneingabe_status"
                        label="bezeichnung"
                        :options="noteneingabestati"
                        :disabled="isDozent"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group" tabindex="3">
                      <v-select
                        v-model="noteneingabe.sichtbar_mit_details"
                        label="bezeichnung"
                        :options="sichtbarkeiten"
                        :disabled="!editable || isDozent"
                        :placeholder="$t('global.visibility')"
                      >
                        <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                      </v-select>
                      <label>{{ $t("global.visibility") }}</label>
                    </div>
                  </div>

                  <div class="col-xl-4">
                    <div class="form-group" tabindex="4">
                      <v-select
                        v-model="noteneingabe.notentyp"
                        label="bezeichnung"
                        :options="notentypen"
                        :disabled="!editable || isDozent"
                        :placeholder="$t('global.scoretype')"
                      >
                        <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                      </v-select>
                      <label>{{ $t("global.scoretype") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="noteneingabe.sichtbar_von"
                        :disabled="isDozent || !editable"
                        placeholder=" "
                        :show-icon="true"
                        tabindex="5"
                        @update="(val) => (noteneingabe.sichtbar_von = val)"
                      />
                      <label>{{ $t("global.Visiblefrom") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <time-field
                      placeholder=" "
                      :initial="noteneingabe.sichtbar_von_uhrzeit"
                      :readonly="isDozent || !editable"
                      :label="$t('global.Time')"
                      tabindex="6"
                      @update="
                        (val) => (noteneingabe.sichtbar_von_uhrzeit = val)
                      "
                    />
                  </div>
                </div>

                <!-- ROW 3 -->

                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group" tabindex="7">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="noteneingabe.sichtbar_bis"
                        placeholder=" "
                        :show-icon="true"
                        :disabled="isDozent || !editable"
                        @update="(val) => (noteneingabe.sichtbar_bis = val)"
                      />
                      <label>{{ $t("global.visibleuntil") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-4" tabindex="8">
                    <time-field
                      placeholder=" "
                      :initial="noteneingabe.sichtbar_bis_uhrzeit"
                      :readonly="isDozent || !editable"
                      :label="$t('global.Time')"
                      @update="
                        (val) => (noteneingabe.sichtbar_bis_uhrzeit = val)
                      "
                    />
                  </div>
                </div>

                <!-- ROW 4 -->
              </div>
            </div>
          </div>

          <!-- ENDE DATEN -->
        </div>

        <!-- START Rechte Seite -->

        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Zuordnung -->

          <container-headline
            :headline="$t('global.assignment')"
            :col="4"
          ></container-headline>

          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <!-- ROW 1 -->

                <div class="row">
                  <div class="col-xl-12" tabindex="9">
                    <single-select-veranstaltung
                      :veranstaltung="veranstaltungen[0]"
                      :typen="msvTypen"
                      route="/Bildung/Klasse/"
                      :editable="false"
                      :open="berechtigungen.b_bildung_fach.read"
                      :label="$t('global.event')"
                    />
                  </div>
                </div>

                <!-- ROW 2 -->

                <div class="row">
                  <div class="col-xl-12" tabindex="10">
                    <multi-select-person
                      :personen="verantwortliche"
                      :label="$t('global.RESponsible')"
                      :open="berechtigungen.m_geschaeftspartner.read"
                      :rollen="mspRollen"
                      :editable="editable"
                    />
                  </div>
                </div>

                <!-- ROW 3 -->

                <div class="row" v-if="eingabeTyp != 'textwahl' && eingabeTyp != 'freitext'">
                  <div class="col-xl-3">
                    <div class="form-group" tabindex="11">
                      <input
                        v-model.number="noteneingabe.anzahl_bewertungen"
                        class="form-control"
                        readonly
                      />
                      <label>{{ $t("global.numberofreviews") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group" tabindex="12">
                      <input
                        v-model.number="noteneingabe.minimum"
                        class="form-control"
                        readonly
                      />
                      <label>{{ $t("global.minimum") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group" tabindex="13">
                      <input
                        v-model.number="noteneingabe.maximum"
                        class="form-control"
                        readonly
                      />
                      <label>{{ $t("global.maximum") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group" tabindex="14">
                      <input
                        v-model.number="noteneingabe.notendurchschnitt"
                        class="form-control"
                        readonly
                      />
                      <label>{{ $t("global.gradeaverage") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- ENDE Zuordnung -->
          </div>

          <!-- ENDE Rechte Seite -->
        </div>

        <div v-if="id" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li
                  class="nav-item"
                  v-if="eingabeTyp != 'textwahl' && eingabeTyp != 'freitext'"
                >
                  <div
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 0 }"
                    @click="swapComponent(0)"
                  >{{ $t("Pruefungen") }}
                  </div
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="eingabeTyp != 'textwahl' && eingabeTyp != 'freitext'"
                >
                  <div
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 1 }"
                    @click="swapComponent(1)"
                  >{{ $t("global.examgrades") }}
                  </div
                  >
                </li>
                <li
                  class="nav-item"
                  v-if="eingabeTyp == 'textwahl' || eingabeTyp == 'freitext'"
                >
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige === 2 }"
                    :to="{
                      name: 'noteneingabe',
                      params: { id: id, anzeige: 2 },
                    }"
                  >{{ $t("global.singlegrades") }}
                  </router-link
                  >
                </li>
              </ul>
            </div>
            <div class="col-xl-12 p-0">
              <div class="tab-container p-3">
                <div v-if="anzeige === 0">
                  <div class="row">
                    <div class="col-xl-12">
                      <pruefungenliste
                        ref="pruefungenliste"
                        :noteneingabe="id"
                        :shown="anzeige === 0"
                        :editable="editable"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="anzeige === 1">
                  <div class="row">
                    <div class="col-xl-12">
                      <notenerfassung
                        :noteneingabe="id"
                        :shown="anzeige === 1"
                        :editable="editable"
                        ref="notenerfassung"
                        @overviewChanged="handleOverviewChanged"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="anzeige === 2">
                  <div class="row">
                    <div class="col-xl-12">
                      <notenerfassung-frei
                        ref="notenerfassungFrei"
                        :noteneingabe="id"
                        :eingabetyp="eingabeTyp"
                        :shown="anzeige == 2 ? true : false"
                        :editable="editable"
                        :key="noteingabeKey"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="noteneingabe-loeschen-modal"
      @confirmed="noteneingabeloeschen"
    />

    <change-watcher-alert/>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import DatePicker from "@/components/Datepicker";

import {apiErrorToAlert} from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";

import {
  LADE_AUSWAHLWERTE_NOTEN,
  LADE_FAECHER
} from "@/store/noten/actions.type";

import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import MultiSelectPerson from "@/components/global/MultiSelectPerson";
import Notenerfassung from "@/components/Reiter/Notenerfassung.vue";
import NotenerfassungFrei from "@/components/Reiter/NotenerfassungFrei";
import Pruefungenliste from "@/components/Reiter/Pruefungenliste.vue";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import TimeField from "@/components/TimeField.vue";
import App from "@/AppApi";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import noteneingabe from "@/store/noteneingabe";

export default {
  name: "Noteneingabe",
  components: {
    SingleSelectVeranstaltung,
    HeadMenu,
    Navbar,
    ContainerHeadline,
    DatePicker,
    Pruefungenliste,
    MultiSelectPerson,
    Notenerfassung,
    NotenerfassungFrei,
    LoeschenModal,
    TimeField
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null
    },
    anzeige: {
      type: Number,
      default: 0,
    },
    kategorieProp: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      newAnzeige: null,
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 25,
      editable: true,
      noteingabeKey: 0,
      isDozent: false,
      isAdmin: false,
      mspRollen: this.$CONST("ROLLEN").DOZENTEN,
      msvTypen: [
        this.$CONST("VERANSTALTUNGSTYPEN").MODULE,
        this.$CONST("VERANSTALTUNGSTYPEN").STUDIES
      ],

      noteneingabe: {
        fach: {
          bezeichnung: this.$t("global.notlinked"),
          noteneingabetyp: {bezeichnung: this.$t("global.notlinked")}
        },
        noteneingabe_status: {bezeichnung: this.$t("global.notlinked")},
        noteneingabe_typ: {bezeichnung: this.$t("global.notlinked")},
        notenberechnung: {bezeichnung: this.$t("global.notlinked")},
        veranstaltungen: [],
        verantwortliche: []
      },

      veranstaltungen: [],
      verantwortliche: [],

      showPruefung: true,

      sortBy: "name",
      sortDesc: false,
      fields: [
        {key: "nummer", sortable: true, label: this.$t("global.num")},
        {key: "name", sortable: true, label: this.$t("global.surname")},
        {key: "vorname", sortable: true, label: this.$t("global.firstname")},
        {key: "typ", sortable: true, label: this.$t("global.type")},
        {key: "pruefung1", sortable: true, label: this.$t("global.examone")},
        {key: "durchschnitt", sortable: true, label: "Ø"},
        {
          key: "korrektur",
          sortable: true,
          label: this.$t("global.correction")
        },
        {
          key: "dispensiert",
          sortable: true,
          label: this.$t("global.dispensed")
        },
        {
          key: "durchschnittDefinitiv",
          sortable: true,
          label: "Ø " + this.$t("global.definitive")
        }
      ]
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id) {
        return this.$t("global.scoreinput");
      } else {
        return this.$t("global.newscoreinput");
      }
    },
    queryParams: function () {
      let params = {
        page: this.page,
        count: this.anzahlProPage
      };

      return params;
    },

    eingabeTyp: function () {
      let eingabeTyp = this.noteneingabe.noteneingabe_typ;

      if (!eingabeTyp) {
        // Übergang vor Migration von Typ aus Fach direkt zu Noteneingabe
        if (!this.noteneingabe.fach || !this.noteneingabe.fach.noteneingabetyp)
          return "noten";

        eingabeTyp = this.noteneingabe.fach.noteneingabetyp;
      }

      if (eingabeTyp.id == this.$CONST("NOTENEINGABETYPEN").CHOICE) {
        return "textwahl";
      }
      if (eingabeTyp.id == this.$CONST("NOTENEINGABETYPEN").TEXT) {
        return "freitext";
      } else {
        return "noten";
      }
      /*
      return eingabeTyp.id == this.$CONST("NOTENEINGABETYPEN").CHOICE
        ? "textwahl"
        : "noten";
        */
    },
    noteneingabestati: {
      get() {
        return this.$store.state.noten.noteneingabestati;
      }
    },
    sichtbarkeiten: {
      get() {
        return this.$store.state.noten.sichtbarkeiten;
      }
    },
    noteneingabetypen: {
      get() {
        return this.$store.state.noten.noteneingabetypen;
      }
    },
    faecher: {
      get() {
        return this.$store.state.noten.faecher;
      }
    },
    notentypen: {
      get() {
        return this.$store.state.noten.notentypen;
      }
    }
  },
  watch: {
    /*newAnzeige: async function (val, oldVal) {
      if ((val === 1 && oldVal === 0) || (val === 0 && oldVal === 1) || (val === 0 && oldVal === null) || (val === 1 && oldVal === null)) {
        switch (val) {
          case 1: {
            if (this.$refs.pruefungenliste !== undefined) {
              await this.$refs.pruefungenliste.pruefungenSpeichern(true);
            } else {
              return
            }
            break;
          }
          case 0: {
            if (this.$refs.notenerfassung !== undefined) {
              await this.$refs.notenerfassung.notenSpeichern(true);
            } else {
              return
            }
            break;
          }
        }
      } else if(oldVal === 2){
        await this.$router.push({name: 'noteneingabe', params: {id: this.id, anzeige: 0}});
      }
    },*/
    noteneingabetypen: function (typen) {
      if (typen && !this.noteneingabe.noteneingabe_typ) {
        let netNoten = this.$CONST("NOTENEINGABETYPEN").GRADES;

        this.noteneingabe.noteneingabe_typ = typen.find(
          (typ) => typ.id == netNoten
        );
      }
    },
    eingabeTyp: function (value) {
      let anzeige = value == "textwahl" || value == "freitext" ? 2 : 0;
      if (this.anzeige == anzeige) return;

      this.$router.push({
        name: "noteneingabe",
        params: {
          id: this.noteneingabe.id,
          anzeige: anzeige
        }
      });
    },
  },
  created() {
    this.loading = true;

    if (
      this.gruppen.find(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").INSTRUCTOR
      )
    )
      this.isDozent = true;

    if (
      this.gruppen.find((gruppe) => gruppe.id == this.$CONST("GRUPPEN").ADMIN)
    )
      this.isAdmin = true;

    this.loadNoteneingabe();
  },
  mounted: function () {
    if (this.noteneingabestati.length == 0)
      this.$store.dispatch(`noten/${LADE_AUSWAHLWERTE_NOTEN}`);

    if (this.faecher.length == 0) this.$store.dispatch(`noten/${LADE_FAECHER}`);
  },
  methods: {
    async swapComponent(anzeige) {
      if ((anzeige === 1 && this.anzeige === 0) || (anzeige === 0 && this.anzeige === 1) || (anzeige === 0 && this.anzeige === null) || (anzeige === 1 && this.anzeige === null)) {
        switch (anzeige) {
          case 1: {
            if (this.$refs.pruefungenliste !== undefined) {
              await this.$refs.pruefungenliste.pruefungenSpeichern(true);
            } else {
              return
            }
            break;
          }
          case 0: {
            if (this.$refs.notenerfassung !== undefined) {

              await this.$refs.notenerfassung.notenSpeichern(true);
            } else {

              return
            }
            break;
          }
        }
      } else if(this.anzeige === 2){
        await this.$router.push({name: 'noteneingabe', params: {id: this.id, anzeige: 0}});
      }
    },
    loadNoteneingabe() {
      if (this.id) {
        App.get("noteneingabe/", {params: {id: this.id}}).then(
          (response) => {
            this.initializeNoteneingaben(response.data);

            this.loading = false;
          }
        ).finally(() => {
          this.loading = false;
        });
      } else {
        this.editable = true;
        this.loading = false;
      }
    },

    oeffneVerantwortlicher() {
      if (this.noteneingabe.verantwortlicher) {
        this.$router.push({
          path:
            "/stammdaten/geschaeftspartner/" +
            this.noteneingabe.verantwortlicher.id
        });
      }
    },

    oeffneVeranstaltung() {
      if (this.noteneingabe.veranstaltung) {
        this.$router.push({
          path: "/bildung/Klasse/" + this.noteneingabe.veranstaltung.id
        });
      }
    },

    selectVeranstaltung() {
    },
    oeffneNotenBerechnung() {
    },
    selectNotenBerechnung() {
    },
    abbrechen() {
      this.$router.push({name: "noteneingabe-liste"});
    },

    initializeNoteneingaben(noteneingabe) {
      if (noteneingabe) {
        this.noteneingabe = noteneingabe;

        this.noteneingabe.fach = noteneingabe.fach ? noteneingabe.fach : null;

        // Für Übergangsphase bis Migration fertig hier noch Übernahme von Noteneingabetyp direkt auf die Noteneingabe

        if (
          !this.noteneingabe.noteneingabe_typ &&
          this.noteneingabe.fach.noteneingabetyp
        ) {
          this.noteneingabe.noteneingabe_typ =
            this.noteneingabe.fach.noteneingabetyp;
        }

        if (this.noteneingabe.noteneingabe_typ) {
          this.showPruefung =
            this.noteneingabe.noteneingabe_typ.id ==
            this.$CONST("NOTENEINGABETYPEN").GRADES
              ? true
              : false;
        }

        this.noteneingabe.notenberechnung = noteneingabe.notenberechnung
          ? noteneingabe.notenberechnung
          : null;

        this.veranstaltungen = noteneingabe.veranstaltungen
          ? noteneingabe.veranstaltungen
          : [{bezeichnung: this.$t("global.notlinked")}];

        if (this.veranstaltungen.length == 0)
          this.veranstaltungen = [{bezeichnung: this.$t("global.notlinked")}];

        this.verantwortliche = noteneingabe.verantwortliche
          ? noteneingabe.verantwortliche
          : [{personName: this.$t("global.notlinked")}];

        if (this.verantwortliche.length == 0)
          this.verantwortliche = [{personName: this.$t("global.notlinked")}];

        this.editable =
          noteneingabe.noteneingabe_status?.id ==
          this.$CONST("NOTENEINGABESTATI").COMPLETED ||
          noteneingabe.noteneingabe_status?.id ==
          this.$CONST("NOTENEINGABESTATI").ARCHIVED
            ? false
            : true;

        if (this.isDozent) {
          this.editable =
            noteneingabe.noteneingabe_status?.id ==
            this.$CONST("NOTENEINGABESTATI").COMPLETED ||
            noteneingabe.noteneingabe_status?.id ==
            this.$CONST("NOTENEINGABESTATI").ARCHIVED
              ? false
              : true;
        }

        if (this.isDozent) {
          /* Dozent darf nicht mehr ändern wenn 'Sichtbar bis' überschritten ist */
          if (this.noteneingabe.sichtbar_bis) {
            let partsDate = this.noteneingabe.sichtbar_bis.split(".");
            let partsTime = this.noteneingabe.sichtbar_bis_uhrzeit.split(":");
            let bisDate = new Date(
              partsDate[2],
              partsDate[1] - 1,
              partsDate[0],
              partsTime[0],
              partsTime[1]
            );
            if (new Date() >= bisDate) this.editable = false;
          }
          if (this.noteneingabe.sichtbar_von) {
            let partsDate = this.noteneingabe.sichtbar_von.split(".");
            let partsTime = this.noteneingabe.sichtbar_von_uhrzeit.split(":");
            let vonDate = new Date(
              partsDate[2],
              partsDate[1] - 1,
              partsDate[0],
              partsTime[0],
              partsTime[1]
            );
            if (new Date() <= vonDate) this.editable = false;
          }
        }

        if (this.berechtigungen.b_bildung_noteneingabe.update == false)
          this.editable = false;

        this.initChangeWatcher(this.noteneingabe);
      } else {
        console.error("noteneingabe ist null");
      }
    },

    speichern() {
      if (this.loading) return;
      this.loading = true;
      let json = this.noteneingabe;
      json.veranstaltungen = this.veranstaltungen;

      json.verantwortliche = this.verantwortliche.length === 0 || this.verantwortliche[0]["personName"] === this.$t("global.notlinked") ? [] : this.verantwortliche;

      switch (this.anzeige) {
        case 0: {
          this.$refs.pruefungenliste.pruefungenSpeichern();
          break;
        }
        case 1: {
          this.$refs.notenerfassung.notenSpeichern();
          break;
        }
        case 2: {
          this.$refs.notenerfassungFrei.notenSpeichern();
          break;
        }
      }

      App.put("noteneingabe/", json, {params: {id: this.id}})
        .then((response) => {
          this.initializeNoteneingaben(response.data);
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.scoreinputsavedsuccessfully")
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          //  this.noteingabeKey++;
          // this.$refs.pruefungenliste.refreshTable();
          this.loading = false;
        });
      // }
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "noteneingabe-loeschen-modal");
    },

    noteneingabeloeschen() {
      let selectedIds = []
      selectedIds.push(this.id)
      App.delete("noteneingabe/", {data: selectedIds}).then(() => {
        this.$router.push({name: "noteneingabe-liste"});
      });
    },
    noteneingabeAbschliessen() {
      this.noteneingabe.noteneingabe_status.id =
        this.$CONST("NOTENEINGABESTATI").COMPLETED;
      this.noteneingabe.noteneingabe_status.bezeichnung = "Abgeschlossen";
      this.speichern();
    },
    handleOverviewChanged(newOverview) {
      this.noteneingabe.maximum = newOverview.maximum
      this.noteneingabe.notendurchschnitt = newOverview.notendurchschnitt
      this.noteneingabe.minimum = newOverview.minimum
      this.noteneingabe.anzahl_bewertungen = newOverview.anzahl_bewertungen
    }
  }
};
</script>

<style></style>
