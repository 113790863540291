<template>
  <div style="margin-top: 0px">
    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              headline="Unsere heutigen Veranstaltungen"
              :showTime="true"
              updateClock
            ></head-menu>
          </div>
        </div>
      </div>

      <!-- START Linke Seite -->
      <div class="col-xl-12 pr-4 leitsystem">
        <b-table
          ref="selectableTable"
          tbody-tr-class="item"
          responsive
          sticky-header
          :items="displayzeiten"
          :fields="fields"
          striped
        >
        </b-table>
      </div>
    </div>
    <div
      style="
        position: absolute;
        left: 0;
        bottom: 5px;
        width: 100%;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
      "
    >
      BX:EDUCATION - Batix Schweiz AG
    </div>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ApiLeitsystem from "@/ApiLeitsystem";
import store from "@/store";

import page from "@/mixins/Page";

export default {
  name: "Geschaeftspartner",
  components: {
    HeadMenu,
  },
  mixins: [page],
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.guidancesystem");
      },
    };
  },
  store,
  props: {},
  data() {
    return {
      page: 1,
      gebaeude: "1750772A00E",
      zeiten: [],
      displayzeiten: [],
      fields: [
        {
          key: "von_uhrzeit",
          label: "Von", //this.$t("global.from"),
          class: "text-center",
          tdClass: "leitsystem-entry",
        },
        {
          key: "bis_uhrzeit",
          label: "Bis", //this.$t("global.till"),
          class: "text-center",
          tdClass: "leitsystem-entry",
        },
        {
          key: "klasse",
          label: "Klasse",
          tdClass: "leitsystem-entry",
          formatter: (value, key, item) => {
            return value ? value.kuerzel : "";
          },
        },
        // {
        //   key: "veranstaltung",
        //   label: "Veranstaltung", //this.$t("global.event"),
        //   tdClass: "leitsystem-entry",
        //   formatter: (value, key, item) => {
        //     return value
        //       ? value.kuerzel + " " + value.bezeichnung
        //       : item.bezeichnung;
        //   },
        // },
        {
          key: "bezeichnung",
          label: "Veranstaltung", //this.$t("global.event"),
          formatter: (value) => {
            return value ? value : "-";
          },
        },
        {
          key: "verantwortlich.personName",
          class: "text-left",
          tdClass: "leitsystem-entry",
          label: "Dozierende",
        },
        {
          key: "raeumeleitsystem",
          class: "text-left",
          tdClass: "leitsystem-entry",
          label: "Raum", //this.$t("global.room"),
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted: function () {
    this.getTimeTable();

    let that = this;
    setInterval(function () {
      that.getTimeTable();
    }, 900 * 1000); // 15 Minuten

    setInterval(function () {
      that.switchDisplay();
    }, 60 * 1000); // 1 Minute

    $("body").css("overflow", "hidden");
  },
  methods: {
    switchDisplay() {
      if (!this.zeiten) return;

      let count = this.zeiten.length;

      if (count / 12 > this.page) this.page++;
      else this.page = 1;

      this.displayzeiten = this.zeiten.slice(
        (this.page - 1) * 12,
        this.page * 12
      );
    },

    getTimeTable() {
      let heute = new Date();
      //const tomorrow = new Date();
      //heute.setDate(tomorrow.getDate() + 1);

      //Api.get("apileitsystem/", {
      //=> localhost Calls zum testen
      ApiLeitsystem.get("/apileitsystem/", {
        params: {
          /*id: this.gebaeude,*/
          //gebaeude: "179233075BD,179233075BF",
          count: 99,
          offset: 0,
          //vondate: "02.06.2021",
          vondate:
            heute.getDate() +
            "." +
            (heute.getMonth() + 1) +
            "." +
            heute.getFullYear(),
          vontime: heute.getHours() - 1 + ":00",
          //bisdate: "02.06.2021",
          bisdate:
            heute.getDate() +
            "." +
            (heute.getMonth() + 1) +
            "." +
            heute.getFullYear(),
          bistime: "23:59",
        },
      }).then((response) => {
        this.zeiten = response.data;

        this.page = 0;
        this.switchDisplay();
      });
    },
  },
};
</script>

<style></style>
