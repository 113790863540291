<template>
  <div class="mx-5">
    <div class="language-selection">
      <b-button variant="outline-primary" class="mx-1" size="sm" @click="changeLanguage('de')">DE</b-button>
      <b-button variant="outline-primary" class="mr-1" size="sm" @click="changeLanguage('fr')">FR</b-button>
      <b-button variant="outline-primary" class="mr-1" size="sm" @click="changeLanguage('en')">EN</b-button>
      <b-button variant="outline-primary" class="mr-1" size="sm" @click="changeLanguage('it')">IT</b-button>
    </div>
    <div class="row mt-10-px">
      <div v-for="(frage, index) in fragen" :key="index" class="col-xl-4 pt-2">
        <div  class="tile-container">
        <div class="tile-categorie-frame">
          <div class="tile-categorie">
            <div class="header">
              <span v-if="frage.sort != 0">{{ frage.sort + " " }}</span><span>{{ frage.designation }}</span>
            </div>
            <div class="body">
              <div class="row">
                <div 
                  v-if="frage.questiontype.designation == 'Singlechoice' || frage.questiontype.designation == 'Multiplechoice'"
                  class="col-xl-12"
                >
                  <column-chart
                    :data="prepareChartData(frage)"
                    height="300px"
                    :colors="[
                      '#add58c',
                    ]"
                    :legend="false"
                    :xtitle="t('public.answers')"
                    :ytitle="t('public.number')"
                  ></column-chart>
                </div>
                <div 
                  v-if="frage.questiontype.designation == 'Text'"
                  class="col-xl-12"
                >
                  <div v-for="(result, index) in frage.results" :key="index">
                      {{ t("public.answer") + " " + (index + 1) + ": " + result.answertext }}
                  </div>
                </div>
                <div 
                  v-if="frage.questiontype.designation == 'Range'"
                  class="col-xl-12"
                >
                <column-chart
                    :data="prepareRangeData(frage)"
                    :colors="[
                      '#1ad7e1',
                    ]"
                    :legend="false"
                    :xtitle="t('public.rangevalue')"
                    :ytitle="t('public.number')"
                  ></column-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import QuestionaireApi from "@/QuestionaireApi";
  import page from "@/mixins/Page";
  
  export default {
    name: "Auswertung",
    components: {  },
    mixins: [ page ],
    props: {
      id: {
      type: String,
      default: null,
    },
    },
    data() {
      return {
        fragen: [],
        translations: {
        de: {
          public: {
            answers: 'Antworten',
            number: 'Anzahl',
            answer: 'Antwort',
            rangevalue: 'Wert'
          }
        },
        fr: {
          public: {
            answers: 'Réponses',
            number: 'Nombre',
            answer: 'Réponse',
            rangevalue: 'Valeur'
          }
        },
        en: {
          public: {
            answers: 'Answers',
            number: 'Number',
            answer: 'Answer',
            rangevalue: 'Value'
          }
        },
        it: {
          public: {
            answers: 'Risposte',
            number: 'Numero',
            answer: 'Risposta',
            rangevalue: 'Valore'
          }
        }
      },
      currentLanguage: 'de'
      };
    },
    created() {
        QuestionaireApi.get("/result/", {
        params: { id: this.id },
        }).then(
          (response) => {
              this.fragen = response.data;
        }
      );
    },
    mounted() {},
    computed: {},
    methods: {
      prepareChartData(frage) {
          return frage.answers.map(answer => {
              const count = frage.results.reduce((acc, result) => {
                  return acc + (result.answers.some(a => a.id === answer.id) ? 1 : 0);
              }, 0);
              return [answer.designation, count]; // Erstellt ein Array mit Label und Count
          });
      },
      prepareRangeData(frage) {
          const rangeStart = parseInt(frage.rangestart, 10);
          const rangeEnd = parseInt(frage.rangeend, 10);
          const rangeCount = new Array(rangeEnd - rangeStart + 1).fill(0);
          // Zählt die Anzahl jeder range_value in den results
          frage.results.forEach(result => {
              const value = result.range_value - rangeStart;
              if (value >= 0 && value < rangeCount.length) {
                  rangeCount[value]++;
              }
          });
          // Erstellt das Datenarray für die Chart-Komponente
          return rangeCount.map((count, index) => {
              return [`${rangeStart + index}`, count];
          });
      },
      changeLanguage(lang) {
      this.currentLanguage = lang;
      },
      t(key) {
        return this.translations[this.currentLanguage][key.split('.')[0]][key.split('.')[1]];
      }      
    },
  };
  </script>
  