<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <ValidationObserver v-slot="{ invalid }" :disabled="true">
          <div class="modal-header">
            <modal-close-button @confirmed="closeModal" />
            <h4 class="modal-title">{{ $t("global.transferregistration") }}</h4>
          </div>
          <div class="modal-body">
            <div v-if="!veranstaltungSpez & !veranstaltungBG">
              <div class="txtTrsf">
                {{ $t("global.specificeventoreventsofcourse") }}
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <button class="btn btnTrsf" @click="unsetLehrgang">
                      {{ $t("global.specificevent") }}
                    </button>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <button class="btn btnTrsf" @click="unsetVeranstaltung">
                      {{ $t("global.fromcourse") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="veranstaltungSpez || veranstaltungBG">
              <div class="row">
                <div class="col-xl-6">
                  <button class="btn btnBack" @click="unsetCheckboxes">
                    <font-awesome-icon
                      icon="fa-solid fa-arrow-left"
                      size="lg"
                    />
                    <span> {{ $t("global.back") }}</span>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <single-select-veranstaltung
                    v-if="veranstaltungSpez"
                    id="anmeldung-uebertragen-veranstaltung"
                    :label="$t('global.event')"
                    :veranstaltung="veranstaltung"
                    :allowOpen="false"
                    :required="true"
                    editable
                    @confirmed="setVeranstaltung"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <single-select-lehrgang
                    v-if="veranstaltungBG"
                    id="anmeldung-uebertragen-lehrgang"
                    :label="$t('global.educationcourse')"
                    :lehrgang="lehrgang"
                    :allowOpen="false"
                    :required="true"
                    editable
                    @confirmed="setLehrgang"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <ValidationProvider
                      rules="required"
                      immediate
                      v-slot="{ passed }"
                    >
                      <v-select
                        v-model="anmeldestatus"
                        v-bind:class="{ 'border-danger': !passed }"
                        label="bezeichnung"
                        :options="anmeldestati"
                        :reduce="(asl) => asl.id"
                        :placeholder="$t('global.status')"
                      >
                        <span slot="no-options">{{
                          $t("global.taptosearch")
                        }}</span>
                      </v-select>
                      <label v-bind:class="{ 'text-danger': !passed }">{{
                        $t("global.status")
                      }}</label>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row" v-if="veranstaltungen.length > 0">
                <div class="col-xl-6">
                  <div class="form-group">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="selectAllEvents"
                    >
                      {{ $t("global.selectall") }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="row" v-if="veranstaltungen.length > 0">
                <div class="col-xl-12">
                  <div class="ueb-group" v-if="veranstaltungBG">
                    <label>{{ $t("global.classes") }}</label>
                    <div
                      v-for="(item, index) in veranstaltungen"
                      :key="'klasse-' + index"
                    >
                      <b-form-checkbox
                        v-if="item.veranstaltungstyp.ist_klasse"
                        v-model="item.selected"
                        class="form-checkbox-input"
                      >
                        {{ item.kuerzel }} {{ item.bezeichnung }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="veranstaltungen.length > 0">
                <div class="col-xl-12">
                  <div
                    class="ueb-group position-relative"
                    v-if="veranstaltungBG || veranstaltungSpez"
                  >
                    <label>{{ $t("global.subjects") }}</label>
                    <div
                      v-for="(item, index) in veranstaltungen"
                      :key="'fach-' + index"
                    >
                      <b-form-checkbox
                        v-if="!item.veranstaltungstyp.ist_klasse"
                        v-model="item.selected"
                        class="form-checkbox-input"
                      >
                        {{ item.kuerzel }} {{ item.bezeichnung }}
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6" v-if="allowCopy">
                  <div class="form-group">
                    <b-form-checkbox v-model="copy" class="form-checkbox-input">
                      {{ $t("global.copyregistration") }}
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="col-xl-6" v-if="allowDelete">
                  <div class="form-group">
                    <b-form-checkbox
                      v-model="loeschen"
                      class="form-checkbox-input"
                    >
                      {{ $t("global.deleteoutputdata") }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="veranstaltungSpez || veranstaltungBG">
            <button type="button" class="btn btn-default" @click="closeModal">
              {{ $t("global.cancel") }}
            </button>
            <button
              v-if="veranstaltungSpez || veranstaltungBG"
              type="button"
              class="btn btn-primary"
              :disabled="invalid"
              @click="uebertragen"
            >
              {{ $t("global.transfer") }}
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import App from "@/AppApi";

import { LADE_AUSWAHLWERTE_ANMELDUNG } from "@/store/anmeldung/actions.type";

import ModalCloseButton from "@/components/global/ModalCloseButton.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import SingleSelectLehrgang from "@/components/global/SingleSelectLehrgang.vue";

export default {
  name: "AnmeldungUebertragenModal",
  components: {
    ModalCloseButton,
    SingleSelectVeranstaltung,
    SingleSelectLehrgang,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    warteSchlange: {
      type: Boolean,
      default: false,
    },
    allowCopy: {
      type: Boolean,
      default: false,
    },
    allowDelete: {
      type: Boolean,
      default: false,
    },
    filterTyp: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      shown: false,
      anmeldestatus: null,
      veranstaltung: { bezeichnung: this.$t("global.notlinked") },
      lehrgang: { bezeichnung: this.$t("global.notlinked") },
      anmeldungen: [],
      // faecher: [],
      // klassen: [],
      veranstaltungen: [],
      // selectAll: false,
      copy: false,
      loeschen: false,
      veranstaltungSpez: false,
      veranstaltungBG: false,
    };
  },
  computed: {
    anmeldestati: {
      get() {
        return this.$store.state.anmeldung.anmeldestati;
      },
    },
  },
  watch: {
    anmeldestati: {
      handler: function (val) {
        if (val.length > 0) {
          if (!this.anmeldestatus || !this.anmeldestatus.id) {
            let anmeldungStatusWarteliste =
              this.$CONST("ANMELDESTATI").WAITINGLIST;

            this.anmeldestatus = this.anmeldestati.find(
              (anmeldestatus) => anmeldestatus.id == anmeldungStatusWarteliste
            );
          }
        }
      },
    },
    veranstaltung: {
      deep: true,
      handler(veranstaltung, oldVeranstaltung) {
        if (!veranstaltung.id || veranstaltung.id == oldVeranstaltung.id)
          return;

        this.getFaecher();
      },
    },
    lehrgang: {
      deep: true,
      handler(lehrgang, oldLehrgang) {
        if (!lehrgang.id || lehrgang.id == oldLehrgang.id) return;

        this.getFaecher();
      },
    },
    /*     faecher: {
      deep: true,
      handler(newVal) {
        if (newVal.some((item) => !item.selected)) {
          console.log("faecher has unselected evnets");
          this.selectAll = false;
        }
      },
    },
    klassen: {
      deep: true,
      handler(newVal) {
        if (newVal.some((item) => !item.selected)) {
          this.selectAll = false;
        }
      },
    }, */
  },
  mounted() {
    this.$bus.$on("openAnmeldungUebertragenModal", (data) => {
      if (!data || data.length == 0) return;
      this.anmeldungen = data;

      if (!this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      if (this.anmeldestati.length == 0)
        this.$store.dispatch(`anmeldung/${LADE_AUSWAHLWERTE_ANMELDUNG}`);

      if (this.allowCopy) this.copy = true;

      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.leereEingaben();
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    getFaecher() {
      /*      Api.get("veranstaltungfach/", {
        params: {
          veranstaltung: this.veranstaltung.id,
          veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").MODULE,
        },
      }).then((response) => {
        this.faecher = response.data;
        this.faecher.forEach((fach) => {
          this.$set(fach, "selected", false);
        });
      }); */

      App.get("/anmeldung/uebertragen/", {
        params: {
          veranstaltung: this.lehrgang?.id ? null : this.veranstaltung.id,
          lehrgang: this.veranstaltung?.id ? null : this.lehrgang.id,
          // veranstaltungstyp: this.$CONST("VERANSTALTUNGSTYPEN").MODULE,
        },
      }).then((response) => {
        this.veranstaltungen = response.data;
        this.veranstaltungen.forEach((event) => {
          this.$set(event, "selected", false);
        });
      });
    },

    uebertragen() {
      // Warteschlange wird anscheinend nirgends mehr verwendet
      if (this.warteSchlange) this.warteschlangeAnmeldung();
      else this.regulaereAnmeldung();
    },

    warteschlangeAnmeldung() {
      // nicht mehr aktuell
      var json = {
        ids: this.anmeldungen,
        anmeldestatus: this.anmeldestatus.id
          ? this.anmeldestatus.id
          : this.anmeldestatus,
        veranstaltung: this.veranstaltung,
      };

      Api.put("anmeldung/anmeldeliste/move", json).then((response) => {
        this.$notify({
          type: "success",
          title: this.$t("global.actionsuccessful"),
          text: "Anmeldung erfolgreich gespeichert.",
        });

        $("#" + this.id).modal("hide");
        this.$emit("changed", response.data.id);
      });
    },

    regulaereAnmeldung() {
      var json = {
        ids: this.anmeldungen,
        copy: this.copy ? this.copy : false,
        anmeldestatus: this.anmeldestatus.id
          ? this.anmeldestatus.id
          : this.anmeldestatus,
        //  veranstaltung: this.veranstaltung,
        //faecher: [],
        veranstaltungen: [],
      };

      /*       this.faecher.forEach((fach) => {
        if (fach.selected) {
          json.faecher.push(fach.id);
        }
      }); */

      if (this.veranstaltung.id) {
        this.veranstaltung.selected = true;
        this.veranstaltungen.push(this.veranstaltung);
      }

      this.veranstaltungen.forEach((event) => {
        if (event.selected) {
          json.veranstaltungen.push(event.id);
        }
      });

      App.post("/anmeldung/uebertragen/", json)
        .then((response) => {
          $("#" + this.id).modal("hide");

          if (this.anmeldungen.length > 1) {
            // Multiple übertragen
            if (this.copy) {
              this.$emit("copied", response);
            } else {
              this.$emit("changed", response);
            }
          } else {
            if (this.copy) {
              this.$emit("copied", response);
            } else {
              this.$emit("changed", response);
            }
          }
        })
        .finally(() => {
          this.leereEingaben();
        });
    },

    setVeranstaltung(veranstaltung) {
      this.veranstaltung = veranstaltung;
    },

    setLehrgang(lehrgang) {
      this.lehrgang = lehrgang;
    },

    selectAllEvents() {
      this.veranstaltungen.forEach((item) => {
        item.selected = true;
      });
    },

    leereEingaben() {
      this.lehrgang = { bezeichnung: this.$t("global.notlinked") };
      this.veranstaltung = { bezeichnung: this.$t("global.notlinked") };
      this.veranstaltungen = [];
      this.veranstaltungBG = false;
      this.veranstaltungSpez = false;
    },

    unsetLehrgang() {
      this.veranstaltungBG = false;
      this.veranstaltungSpez = true;
      this.lehrgang = { bezeichnung: this.$t("global.notlinked") };
      this.veranstaltungen = [];
    },
    unsetVeranstaltung() {
      this.veranstaltungBG = true;
      this.veranstaltungSpez = false;
      this.veranstaltung = { bezeichnung: this.$t("global.notlinked") };
    },
    unsetCheckboxes() {
      this.veranstaltungBG = false;
      this.veranstaltungSpez = false;
    },
  },
};
</script>

<style></style>
