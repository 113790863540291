<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.surveys')"
        :col="3"
      >
        <div class="row">
          <div class="ml-4 mr-2">
            <button
              v-if="berechtigungen.m_korrespondenz.create"
              class="btn btn-success"
              @click="neueUmfrage"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              <span>{{ $t("global.new") }}</span>
          </button>
            <button
              class="btn btn-primary ml-2"
              @click="copy"
              :disabled="!hasValueToCopy"
            >
              <font-awesome-icon icon="fa-duotone fa-copy" class="mr-2" />
              {{ $t("global.Copy") }}
            </button>
          </div>
          <div class="ml-auto mr-2">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="berechtigungen.m_korrespondenz.delete"
            >
              <font-awesome-icon icon="fa-duotone fa-trash" class="mr-2" />{{
                $t("global.delete")
              }}
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              small
              tbody-tr-class="item"
              :items="listData"
              :fields="fields"
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="details"
              thead-tr-class="pointer"
            >
              <template v-slot:head(selected)="header">
                <b-form-checkbox
                  ref="headerCheckbox"
                  v-model="header.selected"
                  @input="selectAllRows(header)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>
            </b-table>

            <infinite-loading
              :identifier="infiniteId"
              @infinite="infiniteHandler"
            >
              <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
              <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
              <div slot="no-results">
                {{ $t("global.nofurtherdatafound") }}
              </div>
            </infinite-loading>
          </div>
        </div>
      </div>
    </div>
    <loeschen-modal
      id="mail-loeschen-modal"
      :selectedIds="this.selectedIds"
      :multiple="true"
      @confirmed="qsLoeschen"
    />
    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import QuestionaireApi from "@/QuestionaireApi";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/QuestionaireDashboard";
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  name: "UmfrageReiter",
  components: {
    ContainerHeadline,
    LoeschenModal,
  },
  mixins: [page, dashboard],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    lehrgang: {
      type: Object,
      required: false,
    },
    veranstaltungId: {
      type: String,
      required:false,
    },
    veranstaltung: {
      type: Object,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },
  store,
  data() {
    return { hasValueToCopy: false };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "designation",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "description",
          sortable: true,
          label: this.$t("global.description"),
        },
        {
          key: "event.kuerzel",
          sortable: true,
          searchable: false,
          label: this.$t("global.event"),
          formatter: (value) => {
            if (value) return value;
            else return "-";
          },
        },
        {
          key: "course.kuerzel",
          sortable: true,
          searchable: false,
          label: this.$t("global.educationcourse"),
          formatter: (value) => {
            if (value) return value;
            else return "-";
          },
        },
      ];
    },

    queryParams: function () {
      var params = {};

      if(!this.veranstaltung){
        params.course = this.lehrgang.id;
      } else {
        params.event = this.veranstaltung.id;
      }

      return params;
    },
  },
  watch: {
    selectedIds(val) {
      val != 0 ? (this.hasValueToCopy = true) : (this.hasValueToCopy = false);
    },
  },
  created() {
    this.initFilter("questionaire-reiter-liste", "/assessment/", true);
  },
  mounted() {
    // console.log("shown " + this.shown)
    // if(this.shown) {
    //     this.getSurveys();
    // }
    this.$bus.$emit("searchWithFilter");
  },
  methods: {
    details(questionaire) {
      this.$router.push({
        name: "questionaire",
        params: { id: questionaire.id, anzeige: 0 },
      });
    },

    getSurveys() {
      QuestionaireApi.get("assessment/", {
        params: {
          event: this.veranstaltungId,
          course: this.lehrgang.id,
        },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    copy() {
      console.log(this.selectedIds.join(","));
      QuestionaireApi.get("assessment/", {
        params: { idsToCopy: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },
    qsLoeschen() {
      QuestionaireApi.delete("assessment/" + this.selectedIds[0], {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "mail-loeschen-modal");
    },
    neueUmfrage() {
      let json = {
        creator: this.angemeldeterMitarbeiter.person,
        designation: this.$t('global.newsurvey'),
        event: null,
        course: null
      };

      if(!this.veranstaltung){
        json.course = this.lehrgang;
      } else {
        json.event = this.veranstaltung;
      }

      QuestionaireApi.post("assessment/", json).then((response) => {
        this.$router.push({
          name: "questionaire",
          params: {
            id: response.data.id,
            anzeige: 0,
          },
        });
      })
    }
  },
};
</script>

<style></style>
